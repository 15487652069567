import { Component, OnInit, NgModule, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { IpServiceService } from 'src/app/Services/common/ip-service.service';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { customValidations } from 'src/app/global/shared/validations.enum';
import { RecoverPasswordComponent } from '../recover-password/recover-password.component';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Title, Meta } from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
  buttons = {
    login: { progress: false, buttonName: 'LOG IN' }
  };
  title = 'Login to Earn Money | Opinionest';
  description = 'Paid online surveys with Opinionest! Earn money for each paid survey you complete! Sign up for free online surveys today!';
  keywords = 'Opinionest, online paid surveys,Register';
  loggedIn: boolean;

  facebookId: any;
  gmailId: any;
  firstName: any;
  lastName: any;
  countryData: any;
  countrCode: any;
  constructor(public _router: Router,
    private _formBuilder: FormBuilder, private apiService: ApiService,
    public dialog: MatDialog, public _IpServiceService: IpServiceService,
    private _snackBar: MatSnackBar, private _HttpServiceService: HttpServiceService,
    public afAuth: AngularFireAuth, private titleService: Title, private meta: Meta,
    private authService: SocialAuthService
  ) {

    this.getIP();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  ngOnInit() {
    //  this.getIP();
    this.titleService.setTitle(this.title);
    this.meta.addTag({ name: 'keywords', content: this.keywords });
    this.meta.addTag({ name: 'description', content: this.description });
    this.meta.addTag({ name: 'og:title', content: this.title });
    this.meta.addTag({ name: 'og:site_name', content: 'Opinionest' });
    this.meta.addTag({ name: 'og:url', content: 'https://opinionest.com/' });
    this.meta.addTag({ name: 'og:description', content: this.description });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'og:image', content: 'https://opinionest.com/assets/images/logo-color.png' });
    this.YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
    let key = 'LogedOut';
    if (localStorage) {
      if (localStorage.getItem(key) === 'LogedOut') {
        this.signOut();
      }
    }
    this.createLoginForm();
  }
  ipAddress: string;
  getIP() {

    this._IpServiceService.getIPAddress().subscribe(response => {
      this.ipAddress = response.ip;
      this.getCountryFlag(this.ipAddress);
    });
  }
  signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.loggedIn = (user != null);
      if (user.email != null) {

        this.gmailId = user.email;
        this.firstName = user.firstName;//?user.givenName:user.name?.split(' ')[0];
        this.lastName = user.lastName;//?user.familyName:user.name?.split(' ').length>1?user.name.split(' ')[1]:'';

        this.onSubmit(user);
      }
    });

    // return new Promise<any>((resolve, reject) => {
    //   let provider = new firebase.auth.GoogleAuthProvider();
    //   firebase.auth().signOut();
    //   this.afAuth.auth.signInWithPopup(provider)
    //   .then(res => {

    //     resolve(res);
    //     if(res.user.emailVerified==false)
    //     {
    //       alert("Invalid password, please login again ");
    //       return;
    //     }
    //     var user = firebase.auth().currentUser;
    //     this.onSubmit(user);
    //   }, err => {
    //     console.log(err);
    //     reject(err);
    //   })
    // }) 
  }

  signInWithFB() {
    firebase.auth().signOut();
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.loggedIn = (user != null);

      if (user.email != null) {
        this.firstName = user.firstName//.split(' ')[0];
        this.facebookId = user.email;
        this.lastName = user.lastName//.split(' ').length>1?user.name.split(' ')[1]:'';

        this.onSubmit(user);
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  signOut(): void {
    localStorage.clear();
  }
  createLoginForm() {
    this.loginForm = this._formBuilder.group({
      emailId: ['', [Validators.required, Validators.pattern(customValidations.email)]],
      password: [[], [Validators.required]],
      recaptcha: ['', Validators.required]
    });
  }
  showSpinner: any = "Login";
  showcaptcha = false;
  attemptCount = 0;
  captchaverified = "no";
  isSocialLogin: boolean = false;
  emailId: any = '';
  password: string = "";
  onSubmit(x) {
    this.emailId = this.loginForm.get('emailId').value;
    this.showSpinner = "Please wait..";
    if (x != '') {
      this.isSocialLogin = true;
      this.emailId = x.email;
      this.password = "";
    }
    else if (this.loginForm.invalid) {
      this.showSpinner = "Login";
      return;
    }
    else {

      this.password = this.loginForm.get('password').value;
    }

    let key = 'LogedIn';
    this.attemptCount = this.attemptCount + 1;
    const url = '/api/Login';
    // this.loginForm.get('password').setValue("");
    this._HttpServiceService.PostWithoutToken(url, {
      'emailId': this.emailId, 'password': this.password, 'isSocialLogin': this.isSocialLogin,
      'facebookId': this.facebookId, 'gmailId': this.gmailId, 'firstName': this.firstName, 'lastName': this.lastName, 'ipAddress': this.ipAddress,
      'countrCode': this.countrCode
    }).subscribe(response => {
      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        if (this._HttpServiceService.setLoginAuth(response)) {
          this.showSpinner = "Login";
          const url = this._router.serializeUrl(
            this._router.createUrlTree(['dashboard'])
          );
          this._HttpServiceService.GetWithoutToken('/api/ExtraActivities/IpAddress?Userid=' + response.data.userId + '&IpAddress=' + this.ipAddress).subscribe(response => {
            this._router.navigate(['dashboard']);
            // window.open(url, '_self');
          });

        }
      }
      else {
        if (x != '') {
          this.showSpinner = "Login";
          alert(response.message);
        }
        else {
          this.showSpinner = "Login";
          this.openSnackBar(response.message, "Alert");
        }

      }
    });


  }
  openPolicy() {
    const dialogRef = this.dialog.open(RecoverPasswordComponent);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  Socialregister(x) {

    this.onSubmit(x);
  }
  showResponse(response) {
    this.captchaverified = "yes";
    this.attemptCount = 0;
    this.showcaptcha = false;
  }
  getCountryFlag(IpAddress) {
    const urlLogin = '/api/Registation/CountryCode?Ip=' + IpAddress;
    this._HttpServiceService.GetWithoutToken(urlLogin).subscribe(response => {
      this.countryData = response.data;
      this.countrCode = this.countryData.countryCode;
    });
  }

  handleExpire() { }
  handleReset() { }
  handleLoad() { }
  handleSuccess(x) { }
}
