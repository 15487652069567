import { Component, OnInit, NgModule,ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-spinthe-wheel',
  templateUrl: './spinthe-wheel.component.html',
  styleUrls: ['./spinthe-wheel.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SpintheWheelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
