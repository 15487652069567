import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-california-privacy-notice",
  templateUrl: "./california-privacy-notice.component.html",
  styleUrls: ["./california-privacy-notice.component.scss"],
})
export class CaliforniaPrivacyNoticeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
