import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'multiselect',
    styles: ['.inputTextSearch { border: 1px solid gray; }'],
    template: `
    <div [formGroup]="form">
    
    <mat-select   (click)="selectdropReclick()"  multiple class="form-control" [id]="field.name" [formControlName]="field.name">
    <input matInput (focusout)="onFocusOutEvent($event)" *ngIf="showText" type="text" placeholder="Search.." autofocus  class="inputTextSearch custSearch"   (keyup)="selectdrop($event)" >
    <mat-option *ngFor="let opt of data" [value]="opt.key">
    {{opt.label}}
    </mat-option>
  </mat-select>
  </div> 
    `
})
export class multiselectComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
  oldLable: any='';
  data: any[];
  showText: boolean=false;

    constructor() {
    }
    ngOnInit() { 
      this.bindMultiSelect("");
      if(this.field.options.length>4)
      { this.showText=true;}
    }
    selectdrop(event)
    {
      this.bindMultiSelect(event.target.value);
    }
    selectdropReclick()
    {
      if (this.data.length==0) {
        this.bindMultiSelect('');
      }
    
    }
    onFocusOutEvent(event)
    {
      if ( this.data.length==0) {
        this.bindMultiSelect('');
      }
    }
    bindMultiSelect(lable)
    {
      if(lable===this.oldLable && this.oldLable!='' && lable!='')
      {
        return;
      }
      this.data=[];
       this.field.options.forEach((item, index)=>
       {
         if (item.label.toLowerCase().includes(lable.toLowerCase())&& lable!='')  {
         this.data.push({"key":item.key,"label":item.label});
         }
         else if(lable=='')
         {
          this.data.push({"key":item.key,"label":item.label});
         }

       });
       this.oldLable=lable;
    }
}