import {
  Component,
  OnInit,
  AfterViewInit,
  Pipe,
  PipeTransform,
  Inject,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
  ViewEncapsulation,
} from "@angular/core";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";

export interface DialogData {
  feedId: number;
}

export class FeedDateModel {
  name: string;
  shortName: string;
  points: string;
  status: string;
  sentDate: string;
}
@Component({
  selector: "app-feed-notifcation",
  templateUrl: "./feed-notifcation.component.html",
  styleUrls: ["./feed-notifcation.component.scss"],
})
export class FeedNotifcationComponent implements OnInit {
  private matdatasource;

  // get refereence to paginator
  @ViewChild(MatPaginator) paginator: MatPaginator;
  Activeindex: number = 0;
  DailyFeed: Array<FeedDateModel> = [];
  CopyDailyFeed: Array<FeedDateModel> = [];
  dashBoardData: any;
  totalPages: number = 0;
  receivedDataList: any;
  pageIndexShow: number = 0;
  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    var viewportHeight = document.documentElement.clientHeight;
    var documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.body.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

    var maxScrollY = documentHeight - viewportHeight;
    // if (verticalOffset==maxScrollY) {
    //   this.onload(1,10);
    // }
    // else if( verticalOffset==0)
    // {
    //   this.onload(1,10);
    // }
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }
  getDashboardData() {
    const url = "/api/DashBoard/DashBoard";
    this._HttpServiceService.Get(url).subscribe((data) => {
      this.dashBoardData = data.data;
    });
  }
  openDialog(FeedId): void {
    const dialogRef = this.dialog.open(FeedetailsModel, {
      panelClass: "my-class",
      width: "550px",
      data: { feedId: FeedId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  constructor(
    private cdr: ChangeDetectorRef,
    private _HttpServiceService: HttpServiceService,
    private dialog: MatDialog
  ) {}
  todayDate: Date = new Date();
  ngOnInit() {
    this.getDashboardData();
    this.onload(0, 10);
  }
  ngAfterViewInit() {
    //
    this.cdr.detectChanges();
  }
  AfterViewInit() {
    //
    this.cdr.detectChanges();
  }
  showloader: boolean = false;
  pageIndex: number = 1;
  pageSize: number = 10;
  lowValue: number = 1;
  highValue: number = 0;

  getPaginatorData(event) {
    if (event.pageIndex === this.pageIndexShow + 1) {
      this.lowValue = this.lowValue + this.pageSize;

      this.highValue = this.highValue + this.pageSize;
      this.pageIndexShow = this.pageIndexShow + 1;
    } else if (event.pageIndex === this.pageIndexShow - 1) {
      this.lowValue = this.lowValue - this.pageSize;

      this.highValue = this.highValue - this.pageSize;
      this.pageIndexShow = this.pageIndexShow - 1;
    }
    this.onload(this.pageIndexShow, this.pageSize);
  }
  receivedData: any;
  feedId: number;
  onload(pageindex: number = 1, pageSize: number = 5) {
    this.showloader = true;
    this.Activeindex = this.Activeindex + pageSize;
    const currentPageIndex = pageindex + 1;

    const url =
      "/api/Home/DailyFeeds?pageindex=" +
      currentPageIndex +
      "&pageSize=" +
      pageSize;
    this._HttpServiceService.Get(url).subscribe((data) => {
      this.receivedDataList = data.data;
      this.showloader = false;
      this.highValue = data.totalCount;
      this.totalPages = this.highValue;
    });
  }

  navigate(url) {
    window.open(url, "_blank");
  }
}

@Component({
  selector: "FeedetailsModel",
  templateUrl: "FeedetailsModel.html",
})
export class FeedetailsModel {
  receivedData: any;
  feedData: any;
  showNextButton: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<FeedetailsModel>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public _HttpServiceService: HttpServiceService
  ) {
    this.getFeedViewData(data.feedId);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  id: any;
  getFeedViewData(feedId) {
    const url = "/api/Home/DailyFeedsDetails/" + feedId;
    this._HttpServiceService.Get(url).subscribe((data) => {
      this.feedData = data.data;
    });
    this.id = feedId;
    const NextView = "/api/Home/DailyFeeds?pageindex=1&pageSize=1000";
    this._HttpServiceService.Get(NextView).subscribe((data) => {
      this.receivedData = data.data;
      for (let index = 0; index < this.receivedData.length; index++) {
        for (
          let index2 = 0;
          index2 < this.receivedData[index]._List.length;
          index2++
        ) {
          const element = this.receivedData[index]._List[index2];
          if (feedId > element.id && feedId == this.id) {
            this.id = element.id;
          }
        }
      }
      if (this.id == feedId) {
        this.showNextButton = false;
      } else {
        this.showNextButton = true;
      }

      if (this.receivedData.length <= 1) {
        if (this.receivedData[0]._List.length <= 1) {
          this.showNextButton = false;
        } else {
          this.showNextButton = true;
        }
      }
    });
  }
}
