import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
   styles: ['.inputTextSearch { border: 1px solid gray; }'],
  selector: 'dynamic-form-builder',
  template:`
    <form (ngSubmit)="saveValue()" style="height: 500px !important;" [formGroup]="form" class="form-horizontal">
   
      <div class="question-list" *ngFor="let field of fields">
            <field-builder [field]="field"  [form]="form"></field-builder>
                 </div>
                 <button type="submit" [disabled]="!form.valid" style="float:right; margin-top:0px!important; margin-left:300px;  position: fixed !important;" mat-flat-button color="primary" class="primary-btn btn-h-40">Next</button>

      <div class="form-row">
     
      </div>
      <div class="form-footer">
  
      </div>
  
    </form>
  `,
})
// <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>
export class DynamicFormBuilderComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() fields: any[] = [];
  form: FormGroup;
  data: any;
  constructor() { }

  ngOnInit() {
    let fieldsCtrls = {};
    for (let f of this.fields) {
      if (f.type != 'checkbox') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
      } else {
        let opts = {};
        for (let opt of f.options) {
          opts[opt.key] = new FormControl(opt.value);
        }
        fieldsCtrls[f.name] = new FormGroup(opts)
      }
    }
    
    this.form = new FormGroup(fieldsCtrls);
    
  }
  ngDistroy() {
    this.fields = []
  }
  onSubmit() {

    console.log(JSON.stringify(this.form.value));
  }
  saveValue()
  {
    this.data=this.form.value;
    this.form.reset();
    this.valueChange.emit(this.data)
  }
}
