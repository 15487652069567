import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { DataService } from "../../dashboard/dashboard.component";
// import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-left-navigation",
  templateUrl: "./left-navigation.component.html",
  styleUrls: ["./left-navigation.component.scss"],
})
export class LeftNavigationComponent implements OnInit {
  @Input() title: string;
  @Input() menuIndex: string;
  @Input() ProfilePercentage: number;
  fullName: any;
  country: any;
  initials: string;
  constructor(
    private dataService: DataService,
    private _router: Router,
    private _iHttpService: HttpServiceService
  ) {}
  ngOnInit() {
    this._iHttpService.isLoggedIn();
    this.dataService
      .getProfilePercentageAndProfileDetails()
      .subscribe((responseList) => {
        this.fullName = responseList[1].data.fullName;
        this.initials = this.fullName
          .split(" ")
          .map((n) => n[0])
          .join("");
        this.country = responseList[1].data.countryName;
      });
  }
  navigate(navigateto) {
    document.body.classList.remove("menu_open");
    if (navigateto === 1) {
      this._router.navigate(["dashboard"]);
    } else if (navigateto === 2) {
      this._router.navigate(["dashboard/offers-and-rewards"]);
    } else if (navigateto === 3) {
      this._router.navigate(["dashboard/my-survey"]);
    } else if (navigateto === 4) {
      this._router.navigate(["dashboard/refer-and-earn"]);
    } else if (navigateto === 5) {
      this._router.navigate(["dashboard/forum"]);
    } else if (navigateto === 6) {
      this._router.navigate(["dashboard/daily-feed"]);
    } else if (navigateto === 7) {
      this._router.navigate(["dashboard/profile-settings"]);
    } else if (navigateto === 8) {
      this._router.navigate(["dashboard/my-survey"]);
    }
  }
  redierctToUrl(url: any): void {
    this._router.navigate([url]);
  }
  signOut(): void {
    var policy = localStorage.getItem("isLoggedIn");
    localStorage.clear();
    localStorage.setItem("cookieValue", policy == "true" ? "1" : "0");
    this._iHttpService.isLoggedIn();
    this._router.navigate(["/login"]);
  }

  toggleMenuOpen() {
    document.body.classList.remove("menu_open");
  }
}
