import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ExtraActivitiesComponent } from "src/app/global/extra-activities/extra-activities.component";
import { ImageconverterService } from "src/app/global/imageconverter.service";
import { HtmlEncodeService } from "src/app/global/shared/html-encode.service";
import { HttpServiceService } from "src/app/Services/common/http-service.service";

@Component({
  selector: "app-blogs",
  templateUrl: "./blogs.component.html",
  styleUrls: ["./blogs.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BlogsComponent implements OnInit {
  title = "Market Research Blogs By Opinionest";
  description =
    "Our blog provides insights from agency employees about the latest market research trends, news & strategies. Subscribe our blog for the latest news in market research.";
  keywords = "blogs";

  constructor(
    private _HttpServiceService: HttpServiceService,
    private _toHTMLEncode: HtmlEncodeService,
    private _ImageconverterService: ImageconverterService,
    public _router: Router,
    private seoService: ExtraActivitiesComponent,
    private titleService: Title,
    private meta: Meta
  ) {}
  receivedData: any;
  copyReceivedData: any;
  topImages: any;
  notTopImages: any;
  rightImages: any;
  leftImges: any;
  centerImages: any;
  centerBottomImages: any;
  bottomImages: any;
  topStoriesImages: any;
  shareBlog: boolean = false;
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({ name: "keywords", content: this.keywords });
    this.meta.addTag({ name: "description", content: this.description });
    this.meta.addTag({ name: "og:title", content: this.title });
    this.meta.addTag({ name: "og:site_name", content: "Opinionest" });
    this.meta.addTag({ name: "og:url", content: "https://opinionest.com/" });
    this.meta.addTag({ name: "og:description", content: this.description });
    this.meta.addTag({ name: "og:type", content: "website" });
    this.meta.addTag({
      name: "og:image",
      content: "https://opinionest.com/assets/images/logo-color.png",
    });
    this.seoService.createLinkForCanonicalURL();
    this.bindBlogList(1);
  }
  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    var viewportHeight = document.documentElement.clientHeight;
    var documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.body.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

    var maxScrollY = documentHeight - viewportHeight;
    if (verticalOffset == maxScrollY) {
      // this.bindBlogList(1);
    } else if (verticalOffset == 0) {
      //this.bindBlogList(-1*this.Activeindex+1);
    }
  }
  toHTML(input: any) {
    return this._toHTMLEncode.toHTML(input);
  }
  Activeindex: number = 0;
  bindBlogList(pageIndex) {
    this.Activeindex = this.Activeindex + pageIndex;
    const url = "/api/Home/BlogList?pageindex=1&pageSize=500";
    this._HttpServiceService.GetWithoutToken(url).subscribe((response) => {
      this.receivedData = response.data ? response.data : [];
      this.topImages = this.receivedData.filter(
        (e) => e.imageType === 1 && e.isTop == true
      );
      this.notTopImages = this.receivedData.filter(
        (e) => [1, 2, 3, 4, 5].includes(e.imageType) && e.isTop !== true
      );

      //leftImges centerImages centerImages
      // this.rightImages = this.receivedData.filter((e) => e.imageType === 2);
      // this.leftImges = this.receivedData.filter((e) => e.imageType === 3);
      // this.centerImages = this.receivedData.filter((e) => e.imageType === 4);
      // this.centerBottomImages = this.receivedData.filter(
      //   (e) => e.imageType === 5
      // );
      this.topStoriesImages = this.receivedData.filter(
        (e) => e.imageType === 6
      );
      this.bottomImages = this.receivedData.filter((e) => e.imageType === 7);
      console.log("topImages", this.topImages.length);
      console.log("notTopImages", this.notTopImages.length);
      // console.log("rightImages", this.rightImages.length);
      // console.log("leftImges", this.leftImges.length);
      // console.log("centerImages", this.centerImages.length);
      // console.log("centerBottomImages", this.centerBottomImages.length);
      console.log("topStoriesImages", this.topStoriesImages.length);
      console.log("bottomImages", this.bottomImages.length);
    });
  }
  getImage(url) {
    this._ImageconverterService
      .getBase64ImageFromURL(url)
      .subscribe((base64data) => {
        // this is the image as dataUrl
        return "data:image/jpg;base64," + base64data;
      });
  }

  transferBlog(newHeader: string) {
    this._router.navigate([
      "blogs/",
      encodeURI(this.getCleanedString(newHeader.toLowerCase())),
    ]);
    //console.log(id.toString());
    $(window).scrollTop(0);
  }
  public getCleanedString(cadena) {
    cadena = cadena.replace(/ /g, "_");
    return cadena;
  }
  openShare(img: any) {
    img.shareBlog = !img.shareBlog;
  }
  closeShare(img: any) {
    img.shareBlog = !img.shareBlog;
  }
}
