import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'src/app/Services/loader.service';

@Component({
  selector: 'app-httploader',
  templateUrl: './httploader.component.html',
  styleUrls: ['./httploader.component.css']
})
export class HttploaderComponent implements OnInit {

 
  loading: boolean;

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      console.log(v);
      this.loading = v;
    });

  }
  ngOnInit() {
  }

}
