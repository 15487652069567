import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HtmlEncodeService {

  constructor() { }
  toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
}
}
