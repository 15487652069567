import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { ReferralUserListComponent } from "../referral-user-list/referral-user-list.component";
import { NgForm } from "@angular/forms";
import { ConnectedPositionStrategy } from "@angular/cdk/overlay";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-refer-and-earn",
  templateUrl: "./refer-and-earn.component.html",
  styleUrls: ["./refer-and-earn.component.scss"],
})
export class ReferAndEarnComponent implements OnInit {
  refferalCode: string = "PLease wait";
  refferalCodeInput: string;
  totalPoint: string = "Loading..";
  totalReferal: number = 0;
  referalExist: boolean = false;
  ProfilePercentage: number = 50;
  constructor(
    public _HttpServiceService: HttpServiceService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  todayDate: Date = new Date();

  ngOnInit() {
    this.getRefferalCode();
    const urlReferral = "/api/Home/ReferralList?pageindex=1&pageSize=500";
    this._HttpServiceService.Get(urlReferral).subscribe((response) => {
      this.totalPoint = response.totalPoint;
      this.referalExist = response.referalExist;
    });
  }
  getRefferalCode() {
    const url = "/api/User/GetReferealLink";
    this._HttpServiceService.Get(url).subscribe(
      (response) => {
        if (response) {
          this.refferalCode = response.data.uniqueCode;
          this.totalReferal = response.data.totalReferal;
        }
      },
      (err) => {}
    );
  }
  copyOnce: number = 0;
  copyToClipboard() {
    if (this.copyOnce == 0) {
      this.copyOnce = 1;
      document.addEventListener("copy", (e: ClipboardEvent) => {
        e.clipboardData.setData("text/plain", this.refferalCode);
        e.preventDefault();
      });
      document.execCommand("copy");
      this.openSnackBar("Copied", "Alert");
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  ViewReferals() {
    const dialogRef = this.dialog.open(ReferralUserListComponent, {});
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }

  toggleNotificationBar() {
    document.body.classList.toggle("open_notiffication_bar");
  }
  onSubmit(f: NgForm) {
    if (f.value.code != "") {
      const url = "/api/User/saveReferalCode";
      let body = {
        referalCode: f.value.code,
      };
      this._HttpServiceService.Post(url, body).subscribe((response) => {
        if (parseInt(JSON.stringify(response.messageCode)) === 1) {
          this.openSnackBar(JSON.stringify(response.message), "Successfull");
        } else {
          this.openSnackBar(JSON.stringify(response.message), "Alert");
        }
      });
    } else {
      this.openSnackBar("Please enter referral code.", "Alert");
    }
  }
}
