import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ApiService } from "src/app/Services/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { customValidations } from "src/app/global/shared/validations.enum";
import { Validators, FormBuilder } from "@angular/forms";
import { ExtraActivitiesComponent } from "src/app/global/extra-activities/extra-activities.component";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  innerWidth: any;
  mobileSliders: any;
  userName: string = "Guest";
  showCookiePolicy: boolean = true;
  cookieValue: string;
  customerReviewsActive: any;
  customerReviewsAll: any;
  ApplinkFormEmail: any;
  ApplinkFormSMS: any;
  totalCashback: any;
  constructor(
    private apiService: ApiService,
    private _HttpServiceService: HttpServiceService,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private seoService: ExtraActivitiesComponent,
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({ name: "keywords", content: this.keywords });
    this.meta.addTag({ name: "description", content: this.description });
    this.meta.addTag({ name: "og:site_name", content: "Opinionest" });
    this.meta.addTag({ name: "og:url", content: "https://opinionest.com/" });
    this.meta.addTag({
      name: "og:description",
      content:
        "Paid online surveys in USA & India with Opinionest are an online panel company, focusing on providing quality survey responses to our clients based on the market research industry. ",
    });
    this.meta.addTag({ name: "og:type", content: "website" });
    this.meta.addTag({
      name: "og:image",
      content: "https://opinionest.com/assets/images/logo-color.png",
    });
    this.seoService.createLinkForCanonicalURL();
    this.getCustomerReviews();
    this.getProfileDetails();
    this.getTotalCashback();
    if (localStorage) {
      this.cookieValue = localStorage.getItem("cookieValue");
    }
    this.createAppLinkForm();
    // if (this.cookieValue !== "1") {
    //   this.showCookiePolicy = true;
    // } else {
    //   this.showCookiePolicy = false;
    // }
  }

  createAppLinkForm() {
    this.ApplinkFormEmail = this._formBuilder.group({
      emailId: [
        "",
        [Validators.required, Validators.pattern(customValidations.email)],
      ],
    });
    this.ApplinkFormSMS = this._formBuilder.group({
      Phone: [
        "",
        [Validators.required, Validators.pattern(customValidations.mobile)],
      ],
    });
  }
  setCookiePolicy(value: any) {
    localStorage.setItem("cookieValue", value);
    this.showCookiePolicy = false;
  }
  getProfileDetails() {
    if (this._HttpServiceService.isLoggedIn()) {
      const url = "/api/User/GetProfile";

      this._HttpServiceService.Get(url).subscribe(
        (response) => {
          if (response) {
            this.userName = response.data.fullName;
          }
        },
        (err) => {}
      );
    }
  }
  sendAppLinkEmail(status) {
    if (status) {
      this.openSnackBar("Please enter valid Email", "Alert");
      return;
    }
    let body = {
      emailId: this.ApplinkFormEmail.value.emailId,
    };
    const EmailAddress = this.ApplinkFormEmail.value.emailId;
    const url = "/api/Home/EmailAppLink";

    this._HttpServiceService.PostWithoutToken(url, body).subscribe(
      (response) => {
        if (response) {
          this.openSnackBar(response.message, "Alert");
        }
      },
      (err) => {}
    );
  }
  sendAppLinkSMS(Status) {
    if (Status) {
      this.openSnackBar("Please enter valid mobile", "Alert");
      return;
    }
    const ContactNumber = this.ApplinkFormSMS.value.Phone;
    const url = "/api/Home/SMSAppLink?PhoneNo=" + ContactNumber;
    this._HttpServiceService.GetWithoutToken(url).subscribe(
      (response) => {
        if (response) {
          this.openSnackBar(response.message, "Alert");
        }
      },
      (err) => {}
    );
  }

  getCustomerReviews() {
    const url = "/api/Home/CustomerReviews?pageindex=1&pagesize=9999";

    this._HttpServiceService.GetWithoutToken(url).subscribe(
      (response) => {
        if (response) {
          this.mobileSliders = response.data;
          this.customerReviewsActive = response.data.slice(0, 3);
          this.customerReviewsAll = response.data.slice(
            3,
            response.data.length
          );
          // if (window.innerWidth >= 992) {
          //   this.customerReviewsActive = response.data.slice(0, 3);
          //   this.customerReviewsAll = response.data.slice(
          //     3,
          //     response.data.length
          //   );
          // } else {
          //   this.customerReviewsActive = response.data.slice(0, 1);
          //   this.customerReviewsAll = response.data.slice(1, 2);
          // }
        }
      },
      (err) => {}
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getTotalCashback() {
    const url = "/api/Home/TotalCashback";

    this._HttpServiceService.GetWithoutToken(url).subscribe(
      (response) => {
        if (response) {
          console.log("total cashback", response);
          console.log(Number(response.data).toLocaleString("en-GB"));
          this.totalCashback = Number(response.data).toLocaleString("en-GB");
        }
      },
      (err) => {}
    );
  }
  title =
    "Paid Online Surveys, USA & India | Earn Money From Surveys - Opinionest";
  description =
    "Paid online surveys in USA & India with Opinionest are an online panel company, focusing on providing quality survey responses to our clients based on the market research industry.";
  keywords =
    "online surveys,USA,India,Opinionest,online,panel,company,quality survey,market research, industry";

  getShortName(fullName: string) {
    return fullName
      .split(" ")
      .map((n) => n[0])
      .join("");
  }
}
