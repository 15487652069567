import { NgModule, Component, OnInit, OnChanges, Input,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShowMoreComponent implements OnInit {
  @Input() text: string;
  @Input() width = '80%';
  @Input() showMore = false;
  text_length: number;
  limit = 100;
  truncating = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

    if (this.text) {
      this.text_length = this.text.length;
    } else {
      this.text_length = 0;
    }
    console.log('text length');
    console.log(this.text_length);
  }

}
