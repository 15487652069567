import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "src/app/authentication/login/login.component";
import { HomeComponent } from "src/app/web-view/home/home.component";
import { ForgotPasswordComponent } from "src/app/authentication/forgot-password/forgot-password.component";
import { RegisterComponent } from "src/app/authentication/register/register.component";
import { DashboardComponent } from "src/app/user-dashboard/dashboard/dashboard.component";
import { PrivacyPolicyComponent } from "src/app/web-view/privacy-policy/privacy-policy.component";
import { TermsofServicesComponent } from "src/app/web-view/termsof-services/termsof-services.component";
import { RecoverPasswordComponent } from "./authentication/recover-password/recover-password.component";
import { ViewEditProfileComponent } from "./user-dashboard/view-edit-profile/view-edit-profile.component";
import { FeedNotifcationComponent } from "./user-dashboard/feed-notifcation/feed-notifcation.component";
import { MandatoryQuestionsComponent } from "./user-dashboard/mandatory-questions/mandatory-questions.component";
import { SurveysComponent } from "./user-dashboard/surveys/surveys.component";
import { OffersAndRewardsComponent } from "./user-dashboard/offers-and-rewards/offers-and-rewards.component";
import { ForumSurveyComponent } from "./user-dashboard/forum-survey/forum-survey.component";
import { OptionalQuestionsComponent } from "./user-dashboard/optional-questions/optional-questions.component";
import { TestimonialComponent } from "./web-view/testimonial/testimonial.component";
import { ReferAndEarnComponent } from "./user-dashboard/refer-and-earn/refer-and-earn.component";
import { QuestionsCategoryComponent } from "./user-dashboard/questions-category/questions-category.component";
import { BlogDetailsComponent } from "./web-view/blog-details/blog-details.component";
import { OfferDetailsComponent } from "./user-dashboard/offer-details/offer-details.component";
import { VideoComponent } from "./global/video/video/video.component";
import { AboutUsComponent } from "./web-view/about-us/about-us.component";
import { ContactUsComponent } from "./web-view/contact-us/contact-us.component";
import { BlogsComponent } from "./web-view/blogs/blogs.component";

import { from } from "rxjs";
import { HowItWorksComponent } from "./web-view/how-it-works/how-it-works.component";
import { WhyJoinUsComponent } from "./web-view/why-join-us/why-join-us.component";
import { DataProtectionComponent } from "./web-view/data-protection/data-protection.component";
import { FaqComponent } from "./web-view/faq/faq.component";
import { CookiesPolicyComponent } from "./web-view/cookies-policy/cookies-policy.component";
import { CategoryQuestionsComponent } from "./user-dashboard/category-questions/category-questions.component";
import { TangoComponent } from "./web-view/tango/tango.component";
import { PageNotFoundErrorComponent } from "./web-view/page-not-found-error/page-not-found-error.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { ExtraActivitiesComponent } from "./global/extra-activities/extra-activities.component";
import { RedirectPageComponent } from "./global/redirect-page/redirect-page.component";
import { LoaderComponent } from "./global/loader/loader.component";
import { ContactUsThankYouComponent } from "./web-view/contact-us-thank-you/contact-us-thank-you.component";
import { PageNotFound404Component } from "./web-view/page-not-found404/page-not-found404.component";
import { GrievancePolicyComponent } from "./web-view/grievance-policy/grievance-policy.component";
import { ChinaPrivacyComponent } from "./web-view/china-privacy/china-privacy.component";
import { CaliforniaPrivacyNoticeComponent } from "./web-view/california-privacy-notice/california-privacy-notice.component";
import { LinkedinProfileComponent } from "./user-dashboard/linkedin-profile/linkedin-profile.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "page-not-found", component: PageNotFoundErrorComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "blogs", component: BlogsComponent },
  { path: "loader.png", component: LoaderComponent },
  { path: "blogdetails", component: BlogDetailsComponent },
  { path: "how-it-works", component: HowItWorksComponent },
  {
    path: "contact-us",
    children: [
      { path: "", component: ContactUsComponent },
      {
        path: "thank-you",
        data: { breadcrumbs: "Thank You" },
        children: [{ path: "", component: ContactUsThankYouComponent }],
      },
    ],
  },

  { path: "extra-activity", component: ExtraActivitiesComponent },
  { path: "redirect", component: RedirectPageComponent },
  {
    path: "login",
    children: [
      { path: "", component: LoginComponent },
      {
        path: "otp",
        data: { breadcrumbs: "otp" },
        children: [{ path: ":Email", component: ForgotPasswordComponent }],
      },
      {
        path: "reset",
        data: { breadcrumbs: "reset" },
        children: [{ path: ":Email", component: ResetPasswordComponent }],
      },
    ],
  },

  { path: "login/register", component: RegisterComponent },
  { path: "login/recoverPassword", component: RecoverPasswordComponent },
  { path: "why-join-us", component: WhyJoinUsComponent },
  { path: "data-protection", component: DataProtectionComponent },
  { path: "faqs", component: FaqComponent },
  { path: "cookies-policy", component: CookiesPolicyComponent },
  {
    path: "dashboard",
    data: { breadcrumbs: "Dashboard" },
    children: [
      { path: "", component: DashboardComponent },
      {
        path: "my-survey",
        data: { breadcrumbs: "MySurvey" },
        children: [{ path: "", component: SurveysComponent }],
      },
      {
        path: "forum",
        data: { breadcrumbs: "Forum" },
        children: [{ path: "", component: ForumSurveyComponent }],
      },
      // { path: 'user/notifications/dailyfeed', component: FeedNotifcationComponent,data: { breadcrumbs: 'Notification' } },
      {
        path: "profile-settings",
        data: { breadcrumbs: "Profile settings" },
        children: [{ path: "", component: ViewEditProfileComponent }],
      },
      {
        path: "linkedin-settings",
        data: { breadcrumbs: "Linkedin settings" },
        children: [{ path: "", component: LinkedinProfileComponent }],
      },
      {
        path: "offers-and-rewards",
        data: { breadcrumbs: "Offers" },
        children: [
          {
            path: "",
            component: OffersAndRewardsComponent,
          },
          {
            path: "details",
            data: { breadcrumbs: "Offers and details" },
            children: [
              {
                path: ":ID",
                component: OfferDetailsComponent,
              },
            ],
          },
        ],
      },
      {
        path: "daily-feed",
        data: { breadcrumbs: "Feeds" },
        children: [{ path: "", component: FeedNotifcationComponent }],
      },
      {
        path: "MandatoryQuestions",
        data: { breadcrumbs: "Mandatory Questions" },
        children: [{ path: "", component: MandatoryQuestionsComponent }],
      },
      {
        path: "QuestionCategories",
        data: { breadcrumbs: "Question Category" },
        children: [
          { path: "", component: QuestionsCategoryComponent },
          {
            path: "OptionalQuestions",
            data: { breadcrumbs: "Questions" },
            children: [
              { path: "", component: OptionalQuestionsComponent },
              { path: ":ID", component: OptionalQuestionsComponent },
            ],
          },
          {
            path: "Questions",
            data: { breadcrumbs: "Questions" },
            children: [
              { path: ":CategoryId", component: CategoryQuestionsComponent },
            ],
          },
        ],
      },
      {
        path: "refer-and-earn",
        data: { breadcrumbs: "Refer and Earn" },
        children: [{ path: "", component: ReferAndEarnComponent }],
      },
    ],
  },
  // { path: 'user/mysurvey', component: SurveysComponent,data: { breadcrumbs: 'mysurvey' } },
  // { path: 'user/forum', component: ForumSurveyComponent,data: { breadcrumbs: 'forum' } },
  // { path: 'user/offers', component: OffersAndRewardsComponent,data: { breadcrumbs: 'offers' } },
  {
    path: "user/profile/editprofile",
    component: ViewEditProfileComponent,
    data: { breadcrumbs: "editprofile" },
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    data: { breadcrumbs: "privacy" },
  },
  {
    path: "grievance-policy",
    component: GrievancePolicyComponent,
    data: { breadcrumbs: "privacy" },
  },
  {
    path: "china-policy",
    component: ChinaPrivacyComponent,
    data: { breadcrumbs: "privacy" },
  },
  {
    path: "california-policy",
    component: CaliforniaPrivacyNoticeComponent,
    data: { breadcrumbs: "privacy" },
  },

  {
    path: "terms-and-conditions",
    component: TermsofServicesComponent,
    data: { breadcrumbs: "termsofService" },
  },
  {
    path: "blogs",
    data: { breadcrumbs: "blogs" },
    children: [
      { path: "", component: BlogsComponent },
      {
        path: ":blog",
        data: { breadcrumbs: "Details" },
        component: BlogDetailsComponent,
      },
    ],
  },
  {
    path: "testimonials",
    component: TestimonialComponent,
    data: { breadcrumbs: "Testimonials" },
  },
  // { path: 'user/MandatoryQuestions', component:MandatoryQuestionsComponent },
  { path: "VideoComponent", component: VideoComponent },
  { path: "tango", component: TangoComponent },
  { path: "404", component: PageNotFound404Component },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
