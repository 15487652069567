import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExtraActivitiesComponent } from "src/app/global/extra-activities/extra-activities.component";
import { ImageconverterService } from "src/app/global/imageconverter.service";
import { HtmlEncodeService } from "src/app/global/shared/html-encode.service";
import { HttpServiceService } from "src/app/Services/common/http-service.service";

@Component({
  selector: "app-blog-details",
  templateUrl: "./blog-details.component.html",
  styleUrls: ["./blog-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BlogDetailsComponent implements OnInit {
  blogId: number = 0;
  otherlist: any;
  newHeader: any;
  blogUrl: string;
  constructor(
    public route: ActivatedRoute,
    public _HttpServiceService: HttpServiceService,
    public _htmlEncodeService: HtmlEncodeService,
    public _ImageconverterService: ImageconverterService,
    public _router: Router,
    private seoService: ExtraActivitiesComponent
  ) {
    this.route.params.subscribe((params) => this.bindBlogDetails(params));
  }
  blogDetails: any;
  shareBlog: boolean = false;
  bindBlogDetails(paramData) {
    this.newHeader = paramData.blog;
    const url = "/api/Home/BlogDetail/?header=" + this.newHeader;
    this._HttpServiceService.GetWithoutToken(url).subscribe(
      (response) => {
        if (response) {
          this.blogDetails = response.data;
          this.otherlist = response.data.otherlist;
          console.log(this.blogDetails);
        }
      },
      (err) => {}
    );
  }
  ngOnInit() {
    this.seoService.createLinkForCanonicalURL();
  }
  toHTML(content) {
    return this._htmlEncodeService.toHTML(content);
  }
  getImage(url) {
    this._ImageconverterService
      .getBase64ImageFromURL(url)
      .subscribe((base64data) => {
        // this is the image as dataUrl
        return "data:image/jpg;base64," + base64data;
      });
  }

  transferBlog(blog: string) {
    this.blogUrl = encodeURI(blog.toLowerCase());
    this._router.navigate(["blogs/", encodeURI(blog.toLowerCase())]);
    //console.log(id.toString());
    $(window).scrollTop(0);
  }
  public getCleanedString(cadena) {
    cadena = cadena.replace(/ /g, "_");
    return cadena;
  }
  openShare(img: any) {
    img.shareBlog = !img.shareBlog;
  }
  closeShare(img: any) {
    img.shareBlog = !img.shareBlog;
  }
}
