import { Component, OnInit, Inject ,ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';

@Component({
  selector: 'app-donate-offer',
  templateUrl: './donate-offer.component.html',
  styleUrls: ['./donate-offer.component.scss']
})
export class DonateOfferComponent implements OnInit {
  OfferData: { OfferImage: string; OfferTitle: string; OfferDetails: string; PointsRequired: string; Favourite: boolean; 
    TotalPoints: number; Eligible: boolean; OfferId: number; Result: any,Disclaimer: string}[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any
  ,public _HttpServiceService:HttpServiceService,
  public _snackBar: MatSnackBar
  ,public dialog: MatDialog) { }
  result: string;
  showSubmitButton: boolean = true;
  showCloseButton: boolean = false;
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  openDonationSnackBar(message: string) {
    this.result = message.split('~')[0];
    this.openSnackBar(JSON.stringify("Donated Successfully"), "Succesfull");
  }
  ngOnInit() {
    this.OfferData = [
      {
       OfferImage: this.data.OfferImage,
       OfferTitle:this.data.OfferTitle,
       OfferDetails: this.data.OfferDetails,
       PointsRequired:this.data.PointsRequired,
       Favourite:this.data.Favourite,
       TotalPoints:this.data.TotalPoints,
       Eligible:this.data.Eligible,
       OfferId: this.data.OfferId,
       Result: this.data.Result,
       Disclaimer: this.data.Disclaimer
      }
     ];
  }

  redeemDonation(OfferId,PointsRequired)
  {
    const url = '/api/Offer/UpdatePoints';
    let body = {
      "OfferId": OfferId,
      "UserId": 0,
      "DeductionPoints": PointsRequired
    };
   
    this._HttpServiceService.Post(url, body).subscribe(response => {
      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        this.openDonationSnackBar(response.message);
      }
    });
    this.showSubmitButton = false;
    this.showCloseButton = true;
  }

}
