import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ChatService, Message } from '../chat.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/scan';
@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit {
  @Output() CloseBottomSheet = new EventEmitter<string>();
  messages: Observable<Message[]>;
  formValue: string;

  constructor(public chat: ChatService) { }
  callParent(): void {
    this.CloseBottomSheet.next('CloseBottomSheet');
  }
  ngOnInit() {
    // appends to array after each new message is added to feedSource
    this.messages = this.chat.conversation.asObservable()
        .scan((acc, val) => acc.concat(val) );
  }

  sendMessage() {
    this.chat.converse(this.formValue);
    this.formValue = '';
    console.log(this.messages)
  }
  openLink(event: MouseEvent): void {
    
    event.preventDefault();
  }
}
