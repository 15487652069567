import { Component, EventEmitter, OnInit, Output ,ViewEncapsulation} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mandatory-questions',
  templateUrl: './mandatory-questions.component.html',
  styleUrls: ['./mandatory-questions.component.css'],
  providers:  []
})
export class MandatoryQuestionsComponent implements OnInit {
 public form: FormGroup;
  unsubcribe: any
  count:number=0;
  mySubscription: any;
  ProfilePercentage: any;
  @Output() callEvent = new EventEmitter<string>();

  GetQuestionCount(): void {
    
    this.callEvent.next('GetQuestionCount');
  }
  constructor(private router: Router, private _HttpServiceService:HttpServiceService,private _snackBar:MatSnackBar) {
    this.getQuestions();
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
};
this.router.events.subscribe((evt) => {
  if (evt instanceof NavigationEnd) {
     this.router.navigated = false;
     window.scrollTo(0, 90);
  }
});
  }
  ngOnInit() { 
    this.getDashboardData();
  }
  public fields: any[] = [];
  onUpload(e) { }
  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  QuestionData:any;
  NoQuestion:any=false;
  getQuestions()
  {
    const url="/api/QuestionAnswer/GetQuestion?MandatoryType=true"
    this._HttpServiceService.Get(url).subscribe(response => {
      if(response.data.answer==null)
      {
        this.NoQuestion=true;
      }
  else if (response.data.question.type==='dropdown') {
    response.data.question.type='dropdown'
  }else if(response.data.question.type==='radiobutton'||response.data.question.type==='matrixradiobutton')
  {
    response.data.question.type='radio'
  }else if(response.data.question.type==='multiselect')
  {
    response.data.question.type='multiselect'
  }else if(response.data.question.type==='checkbox' || response.data.question.type==='matrixcheckBox')
  {
    response.data.question.type='checkbox'
  }
  else
  {
    response.data.question.type='text'
  }

  this.QuestionData=response.data.question;
  this.fields=[
    {
      type: response.data.question.type,
      name: 'key',
      label: response.data.question.profileQuestion,
      value: '',
      required: true,
      options: response.data.answer.map(e => {
        return {
          key: e.profileAnswerId,
          label: e.profileAnswer
        };
      })
    }]
      });
     
      this.fields=[ {
        type: '',
        name: 'key',
        label: '',
        value: '',
        required: true,
      }
    ]
  }
  answerId:any=0;
  SaveAnswer(x:any) { 
    this.answerId=x.key;
    const url = '/api/QuestionAnswer/SaveQuestionAnswer';
    this._HttpServiceService.Post(url, {
    profileQuestionId: this.QuestionData.profileQuestionId,
    profileAnswerId:String(x.key),
    profileAnswer:String(x.key),
    gpcid: this.QuestionData.gpcid,
    mandatory: true
  }).subscribe(response => {
    if(response.messageCode==1)
    {    
      this.getQuestions();
      this.openSnackBar(response.message, "Success");
      this.getDashboardData();
      this.GetQuestionCount();
      x=null;
  }
  else
  {
    this.openSnackBar(response.message, "Alert");
  }
  });
   
  }
  getDashboardData()
  {
    const url = '/api/DashBoard/DashBoard';
    this._HttpServiceService.Get(url).subscribe(response => {
    
      this.ProfilePercentage=response.data.profileComplete;
      
    });
  }
}

