import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { abort } from 'process';

@Component({
    selector: 'dropdown',
    template: `
      <div [formGroup]="form">
     

      <mat-select (click)="selectdrop($event)" class="form-control" [id]="field.name" [formControlName]="field.name">
      <input matInput type="text" *ngIf="showText"  placeholder="Search.." autofocus  class="inputTextSearch custSearch"  (keyup)="selectdrop($event)" >
          <mat-option  *ngFor="let opt of data" [value]="opt.key">
      {{opt.label}}
      </mat-option>
    </mat-select>
    </div> 
    `
})
export class DropDownComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
    fieldCopy:any={"key":"","label":""};
    data = new Array();
    oldLable: any='';
  showText: boolean=false;;
    constructor() {
    }
    ngOnInit() { 
      this.form.reset();
      this.binddropdown("");
      if(this.field.options.length>4)
      { this.showText=true;}
     
    }
    selectdrop(event)
    {
      
      if (event.target.value==undefined) {
        event.target.value='';
      }
      this.binddropdown(event.target.value);
      
    }
    binddropdown(lable)
    {
      if(lable===this.oldLable && this.oldLable!='')
      {
        return;
      }
      
      this.data=[];
       this.field.options.forEach((item, index)=>
       {
         if (item.label.toLowerCase().includes(lable.toLowerCase())) {
         this.data.push({"key":item.key,"label":item.label});
         }
         else if(lable=='')
         {
          this.data.push({"key":item.key,"label":item.label});
         }
       });
       this.oldLable=lable;
       if(this.data.length==0)
       { 
         this.form.reset()
       }
    }
}
