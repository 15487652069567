import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { ExtraActivitiesComponent } from 'src/app/global/extra-activities/extra-activities.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataProtectionComponent implements OnInit {
  title = 'Data Protection | Opinionest';
  description='Data privacy and security are our primary concerns. We take on the responsibility of securely processing and storing your personal data.';
  keywords='Data,privacy, security';

  constructor(private seoService:ExtraActivitiesComponent,private titleService: Title,private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name: 'keywords', content: this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'og:title', content: this.title});
    this.meta.addTag({name: 'og:site_name', content: 'Opinionest'});
    this.meta.addTag({name: 'og:url', content: 'https://opinionest.com/'});
    this.meta.addTag({name: 'og:description', content:this.description});
    this.meta.addTag({name: 'og:type', content: 'website'});
    this.meta.addTag({name: 'og:image', content: 'https://opinionest.com/assets/images/logo-color.png'});
    this.seoService.createLinkForCanonicalURL();
  }

}
