import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { Router } from "@angular/router";
//import { NgxSpinnerService } from 'ngx-spinner';
export interface SurveyData {
  Sno: number;
  Title: string;
  Categories: string;
  Points: string;
  PointsStatus: string;
  Url: string;
  Status: string;
}

@Component({
  selector: "app-surveys",
  templateUrl: "./surveys.component.html",
  styleUrls: ["./surveys.component.scss"],
})
export class SurveysComponent implements OnInit {
  displayedColumns: string[] = [
    "Sno",
    "Title",
    "Categories",
    "Points",
    "PointsStatus",
    "Url",
    "Status",
  ];
   dataSource = []; // Your data source here
  isModalOpen = false;
  selectedField: any;
  totalRecords: number;
  totalPages: number;
  showloader: boolean;
  SurveyCategory: any;
  SurveyStatus: any;
  selectedCategory: number = 0;
  selected: number = 0;
  showloaderPage: boolean;
   @Input() data: any; // Replace 'any' with the appropriate type
  isOpen = false;
  constructor(
    public _router: Router,
    private _HttpServiceService: HttpServiceService
  ) {}
  todayDate: Date = new Date();
  ngOnInit() {
    this.showloader = false;
    this.bindSurveyCategory();
    this.bindStatus();
    this.bindTable(0, 10);
  }
  pagelength: number = 100;
  ProfilePercentage: number = 40;
  TotalPoints: number = 0;
  SurveyData: any;
  bindTable(pageindex: number = 1, pageSize: number = 10) {
    this.showloaderPage = true;
    pageindex = pageindex + 1;
    const url =
      "/api/MySurvey?pageindex=" +
      pageindex +
      "&pagesize=" +
      pageSize +
      (this.selected != 0 ? "&statusId=" + this.selected : "") +
      (this.selectedCategory != 0
        ? "&CategoryId=" + this.selectedCategory
        : "");
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.dataSource = response.data.mySurveyDataListEntites;
      this.bindStaticSurvey();
      this.totalRecords = response.data.totalRecords;
      this.TotalPoints = response.data.totalRewardPoints;
      this.highValue = response.data.totalRecords;
      this.totalPages = this.highValue;
      this.showloaderPage = false;
    });
  }
  bindSurveyCategory() {
    const url = "/api/MySurvey/QuestionCategory";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.SurveyCategory = response.data;
      console.log(this.SurveyCategory);
    });
  }
  bindStatus() {
    const url = "/api/MySurvey/surveyStatus";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.SurveyStatus = response.data;
      console.log(this.SurveyStatus);
    });
  }
  bindStaticSurvey() {
    const url = "/api/MySurvey/StaticBannerSurvey";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.SurveyData = response.data.mySurveyDataListEntites;
      this.showloader = false;
    });
  }
  transfer(url) {
    window.open(url, "_blank");
  }
  pageIndex: number = 1;
  pageSize: number = 10;
  lowValue: number = 1;
  highValue: number = 0;
  pageIndexShow: number = 0;
  getPaginatorData(event) {
    if (event.pageIndex === this.pageIndexShow + 1) {
      this.lowValue = this.lowValue + this.pageSize;

      this.highValue = this.highValue + this.pageSize;
      this.pageIndexShow = this.pageIndexShow + 1;
    } else if (event.pageIndex === this.pageIndexShow - 1) {
      this.lowValue = this.lowValue - this.pageSize;

      this.highValue = this.highValue - this.pageSize;
      this.pageIndexShow = this.pageIndexShow - 1;
    }
    this.bindTable(this.pageIndexShow, this.pageSize);
  }
  SelectCategory() {
    this.pageIndex = 0;
    this.pageIndex = 1;
    this.pageSize = 10;
    this.lowValue = 1;
    this.highValue = 0;
    this.pageIndexShow = 0;
    this.bindTable(0, 10);
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }

  

  openInformation(field: any) {
    this.selectedField = field; 
    this.isModalOpen = true; 
  }

  closeModal() {
    this.isModalOpen = false; 
  }
}
