export const customValidations = {
    allowedCharacters: '[a-zA-Z0-9_@#$%&()*.,?/:;! |\n]*',
    AlphabetOnly: '[a-zA-Z ]+',
    AlphaNumericOnlyWithSpace: '^[0-9a-zA-Z ]+$',
    AlphaNumericOnly: '^[0-9a-zA-Z]+$',
    NumericOnly: '[0-9]*',
    NumericDecimal: '^[-+]?[0-9]+\.[0-9]+$',
    NumericOrDecimal: '^\\d{0,11}(\\.\\d{1,2})?$', // 13,2
    NumericOrDecimal72: '^\\d{0,7}(\\.\\d{1,2})?$', // 7,2
    NumericOrDecimal32: '^\\d{0,3}(\\.\\d{1,2})?$', // 3,2
    NumericOrDecimalGeneric: '^\\d{0,11}(\\.\\d{1,20})?$',
    Percentage: '^\\d*[0-9](|.[0-9]{1,2})?$',
    NumericOnlyWithoutZero: '^[1-9][0-9]*?',
    // :tslint:disable-next-line:max-line-length
    // website :  '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',
    // tslint:disable-next-line:max-line-length
    website: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    notZero: '[^0]+',
    // :tslint:disable-next-line:max-line-length
    email: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
    pan: '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$',
    aadhar: '^[0-9]{12}$',
    passport: '^[a-zA-Z0-9]{8}$',
    mobile: '[0-9]{8,15}$',
    // date: '  ^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$',
    // date: '^(?:\s*(Sun|Mon|Tue|Wed|Thu|Fri|Sat),\s*)?(0?[1-9]|[1-2][0-9]|3[01])\s+(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+(19[0-9]{2}|[2-9][0-9]{3}|[0-9]{2})\s+(2[0-3]|[0-1][0-9]):([0-5][0-9])(?::(60|[0-5][0-9]))?\s+([-\+][0-9]{2}[0-5][0-9]|(?:UT|GMT|(?:E|C|M|P)(?:ST|DT)|[A-IK-Z]))(\s*\((\\\(|\\\)|(?<=[^\\])\((?<C>)|(?<=[^\\])\)(?<-C>)|[^\(\)]*)*(?(C)(?!))\))*\s*$',
    passregex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,12}$',
    onlyYoutube: '^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$'
};
export const redirectURL=
{
    Dashboard:'/dashboard',
    Mysurvey:'/dashboard/my-survey',
    Unsubscribe:'/dashboard/profile-settings'
};

// .net email regex
// tslint:disable-next-line:max-line-length
// email = "^(?()(.+?(?<!\\)@)|(([0-9a-z]((\.(?!\.))|[-!#\$%&'\*\+/=\?\^`\{\}\|~\w])*)(?<=[0-9a-z])@))(?(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-z][-\w]*[0-9a-z]*\.)+[a-z0-9][\-a-z0-9]{0,22}[a-z0-9]))$";

// export enum validationMessages {
//     required = 'Required',
//     numericOnly = 'Numbers only',
//     allowedSpecialCharacters = 'Only a-z, A-Z, 0-9 and (@ # $ % & () *.,? / : ; ! |) special characters are allowed'
// }
