import { Component, OnInit, NgModule, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { IpServiceService } from 'src/app/Services/common/ip-service.service';
import { customValidations } from 'src/app/global/shared/validations.enum';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { Route } from '@angular/compiler/src/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
  buttons = {
    Forgot: { progress: false, buttonName: 'Forgot' }
  };
  email: string;
  isSocialLogin: boolean;
  emailId: any;
  password: string;
  submitClicked: boolean = false;
  phone: string;
  constructor(public _router: Router, public route: ActivatedRoute,
    private _formBuilder: FormBuilder, private apiService: ApiService,
    private ip: IpServiceService, private _HttpServiceService: HttpServiceService,
    private _snackBar: MatSnackBar, public afAuth: AngularFireAuth
  ) { }
  otpTimer: number;
  OTPForm: FormGroup;
  TIMERVALUE: number = 60;
  ngOnInit() {
    if (localStorage) {
      this.email = localStorage.getItem("registerEmail");
      this.phone = localStorage.getItem("phone");
    }
    this.getIP();
    this.YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
    this.createOTPForm();
    this.startTimer();
  }
  startTimer() {
    this.otpTimer = this.TIMERVALUE;
    const timer = setInterval(() => {
      this.otpTimer -= 1;
      if (this.otpTimer <= 0) {
        clearInterval(timer)
      }
    }, 1000);
  }
  ipAddress: string;
  getIP() {
    this.ip.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
  createOTPForm() {
    this.OTPForm = this._formBuilder.group({
      // otp: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4),
      // Validators.pattern(customValidations.NumericOnly)]],
      emailOtp: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4),
      Validators.pattern(customValidations.NumericOnly)]],
      recaptcha: ['', Validators.required]
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  onSubmit() {
    this.submitClicked = true;
    if (this.OTPForm.invalid) {
      return;
    }
    let otp =
    {
      "otp": "0",  //this.OTPForm.value.otp == "" ? "0" : this.OTPForm.value.otp,
      "emailOtp": this.OTPForm.value.emailOtp == "" ? "0" : this.OTPForm.value.emailOtp,
      "emailId": this.email
    }
    const url = '/api/Registation/OtpVerify';
    this._HttpServiceService.PostWithoutToken(url, otp).subscribe(response => {

      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        this.submitClicked = false;
        this._router.navigate(['/login']).then(() => this.openSnackBar(response.message, "Succesfull"))

      }
      else {
        this.submitClicked = false;
        this.openSnackBar(JSON.stringify(response.message), "Alert");
      }

    },
      (error: any) => {
        this.openSnackBar(JSON.stringify(error.message), "Error");

      }
    );
  }
  handleExpire() { }
  handleReset() { }
  handleLoad() { }
  handleSuccess(x) { }

  resendOtp() {
    let key = 'LogedIn';
    const url = '/api/Registation/ReGenerateOtp';
    this._HttpServiceService.PostWithoutToken(url, { 'otp': 0, 'emailOtp': 0, 'emailId': this.email }).subscribe(response => {
      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        this.openSnackBar(JSON.stringify(response.message), "Succesfull");
        this.startTimer();
      }
      else {
        this.openSnackBar(JSON.stringify(response.message), "Alert");
      }
    },
      (error: any) => {
        this.openSnackBar(JSON.stringify(error.message), "Error");
      }
    );

  }
}
