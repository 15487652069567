import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraActivitiesComponent } from 'src/app/global/extra-activities/extra-activities.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {
  title = 'Frequently Ask Questions | Opinionest';
  description='Find the answer to all your queries. Reach out to us and get all your queries solved!';
  keywords='Find the answer, FAQ';

  constructor(private _router:Router,private seoService:ExtraActivitiesComponent,private titleService: Title,private meta: Meta) { }

  ngOnInit() {
    this.seoService.createLinkForCanonicalURL();
    this.titleService.setTitle(this.title);
    this.meta.addTag({name: 'keywords', content: this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'og:title', content: this.title});
    this.meta.addTag({name: 'og:site_name', content: 'Opinionest'});
    this.meta.addTag({name: 'og:url', content: 'https://opinionest.com/'});
    this.meta.addTag({name: 'og:description', content:this.description});
    this.meta.addTag({name: 'og:type', content: 'website'});
    this.meta.addTag({name: 'og:image', content: 'https://opinionest.com/assets/images/logo-color.png'});
    this.seoService.createLinkForCanonicalURL();
  }
  redirectPage(type: number) {
    if (localStorage.getItem('isLoggedIn') == "true") {
      if(type == 1){
        this._router.navigate(['dashboard/my-survey']);
      }
      if(type == 2){
        this._router.navigate(['dashboard/offers-and-rewards']);
      }
      if(type == 3){
        this._router.navigate(['dashboard/refer-and-earn']);
      }
    }
    else{
      this._router.navigate(['/login']);
    }
  }
}
