import { Component, OnInit, Inject ,ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { OfferThankYouComponent } from '../offer-thank-you/offer-thank-you.component';

@Component({
  selector: 'app-redeem-offer',
  templateUrl: './redeem-offer.component.html',
  styleUrls: ['./redeem-offer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RedeemOfferComponent implements OnInit {
  OfferData: { OfferImage: string; OfferTitle: string; OfferDetails: string; PointsRequired: number; 
  Favourite: boolean; TotalPoints: number; Eligible: boolean; OfferId: number; ButtonVisible: boolean,
  Disclaimer: string,TotalAvailablePoints: number,utId:any,currency: any,pointsRequried: number,
  pointRedeemMessage:any}[];
  result: any;
  Submit:any="Submit";
  quantityValue: number = 1.00;
  PointsRequiredForOffer: number = 0;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any
  ,public _HttpServiceService:HttpServiceService,
  public _snackBar: MatSnackBar
  ,public dialog: MatDialog) { }
  pointRedeemMessage:any='';
  ngOnInit() {
    this.pointRedeemMessage=this.data.pointRedeemMessage; 
    this.OfferData = [
      {
       OfferImage: this.data.OfferImage,
       OfferTitle:this.data.OfferTitle,
       OfferDetails: this.data.OfferDetails,
       PointsRequired:this.data.PointsRequired,
       Favourite:this.data.Favourite,
       TotalPoints:this.data.TotalPoints,
       Eligible:this.data.Eligible,
       OfferId: this.data.OfferId,
       ButtonVisible: this.data.ButtonVisible,
       Disclaimer: this.data.Disclaimer,
       TotalAvailablePoints: this.data.TotalAvailablePoints,
       utId:this.data.utId,
       currency: this.data.currency,
       pointsRequried: this.data.pointsRequried,
       pointRedeemMessage:this.data.pointRedeemMessage
      },
     ];
  }
  animal: any;
  openThankYouDialog(OfferId: number,OfferTitle: string, OfferImage: string, PointsRequired: number,
    Favourite: boolean, TotalPoints: number, Eligible: boolean, result: any
    ,Disclaimer: string): void {
      
    const dialogRef = this.dialog.open(OfferThankYouComponent, {
      width: '700px',
      data: {
        OfferId:OfferId,
        OfferTitle: OfferTitle,
        OfferImage: OfferImage,
        PointsRequired: PointsRequired,
        Favourite: Favourite,
        TotalPoints: TotalPoints,
        Eligible: Eligible,
        Result: result,
        Disclaimer: Disclaimer
      },
      disableClose: false
    }
    );
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }
  openSnackBarMessage(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  openSnackBar(OfferId: number,OfferTitle: string, OfferImage: string, PointsRequired: number,
    Favourite: boolean, TotalPoints: number, Eligible: boolean,message: string,Disclaimer: string) {
    this.result = message.split('~')[0];
    this.openThankYouDialog(OfferId,OfferTitle,OfferImage,PointsRequired,Favourite,TotalPoints,Eligible,this.result,Disclaimer);
  }
  Confrirmation:number=0
  waitForDisplay:boolean=false;
  offerRedeem(OfferId: number,OfferTitle: string, OfferImage: string, PointsRequired: number,
    Favourite: boolean, TotalPoints: number, Eligible: boolean,Disclaimer: string,utId:any
    ,currency: string)
  {
    
    if (this.Confrirmation==0) {
      this.Confrirmation=1;
      this.Submit="Confirm";
      return;
    }
    
    let pointBody = {
      "points": Number(PointsRequired) * this.quantityValue,
      "currency":currency
    };
    this.waitForDisplay=true;
    const urlVal = '/api/Offer/ConvertPointToCurrency';
    this._HttpServiceService.Post(urlVal, pointBody).subscribe(response => {
      if(response.messageCode != '1'){
        this.openSnackBarMessage("You do not have enough points to redeem this offer.", "Validation");
        this.waitForDisplay=false;
        return;
      }
    });

    const url = '/api/Offer/UpdatePoints';
    let body = {
      "OfferId": OfferId,
      "UserId": 0,
      "DeductionPoints":(PointsRequired * this.quantityValue).toString()+'.00',
      "transection_amount":(PointsRequired * this.quantityValue).toString()+'.00',
      "payment_type":"2",
      "utid":utId,
      "logoPath":OfferImage
    }
    
    this.PointsRequiredForOffer = PointsRequired;
    this.waitForDisplay=true;
    this._HttpServiceService.Post(url, body).subscribe(response => {
      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        this.openSnackBar(OfferId,OfferTitle,OfferImage,PointsRequired,Favourite,TotalPoints,Eligible,response.message,Disclaimer);
        this.waitForDisplay=false;
      
      }
      else
      {
        this.waitForDisplay=false;
        this.openSnackBarMessage(response.message, "Validation");
      }
    });
  }
  closePopup()
  {
    this.Confrirmation=0;
    this.Submit="Submit";
    this.waitForDisplay=false;
    this.dialog.closeAll();
  }
  onKey(value: string, currency: string,PointsRequired: Number) {
    let pointBody = {
      "points": (Number(PointsRequired) * Number(value)),
      "currency": currency
    };
    this.waitForDisplay=true;
    if(Number(value)>0){
    const urlVal = '/api/Offer/ConvertPointToCurrency';
    this._HttpServiceService.Post(urlVal, pointBody).subscribe(response => {
      if(response.messageCode != '1'){
        this.waitForDisplay=false;
        this.openSnackBarMessage("You do not have enough points to redeem this offer.", "Validation");
        return;
      }
    });
  }
    this.quantityValue = Number(value)==0? 1.00 : Number(value);
  }
}

