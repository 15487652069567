import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-poll-and-share',
  templateUrl: './poll-and-share.component.html',
  styleUrls: ['./poll-and-share.component.css']
})
export class PollAndShareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
