import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'radio',
    styles: ['.inputTextSearch { border: 1px solid gray; }'],
    template: `
      <div [formGroup]="form" >
      <mat-radio-group  [id]="field.name"  [(ngModel)]="chosenItem" [formControlName]="field.name">
      <input matInput type="text" *ngIf="showText" placeholder="Search.." autofocus class="inputTextSearch custSearch"  (keyup)="selectdrop($event)" >
      <mat-radio-button class="tp-radio-button" *ngFor= "let opt of data" [value]="opt.key">
      {{opt.label}}
       </mat-radio-button>
       </mat-radio-group>
      </div> 
    `
})
export class RadioComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
  oldLable: any;
  data: any[];
  showText: boolean=false;
  chosenItem: any;
  constructor() {
  }
  ngOnInit() { 
    this.bindRadioButtons("");
    this.chosenItem= this.data[0].key;
    if(this.field.options.length>4)
    { this.showText=true;}
  }
  selectdrop(event)
  {
    this.bindRadioButtons(event.target.value);
  }
    bindRadioButtons(lable)
    {
      if(lable===this.oldLable && this.oldLable!='')
      {
        return;
      }
      this.data=[];
       this.field.options.forEach((item, index)=>
       {
         if (item.label.toLowerCase().includes(lable.toLowerCase())) {
         this.data.push({"key":item.key,"label":item.label});
         }
         else if(lable=='')
         {
          this.data.push({"key":item.key,"label":item.label});
         }
       });
       this.chosenItem= this.data[0].key;
       this.oldLable=lable;
    }
}