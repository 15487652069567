import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersAndRewardsComponent } from './offers-and-rewards/offers-and-rewards.component';
import { SurveysComponent } from './surveys/surveys.component';
import { ReferAndEarnComponent } from './refer-and-earn/refer-and-earn.component';
import { PollAndShareComponent } from './poll-and-share/poll-and-share.component';
import { FeedNotifcationComponent, FeedetailsModel } from './feed-notifcation/feed-notifcation.component';
// import { MyAccountComponent } from './my-account/my-account.component';
import { ViewEditProfileComponent } from './view-edit-profile/view-edit-profile.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftNavigationComponent } from './common/left-navigation/left-navigation.component';
import { HeaderComponent } from './common/header/header.component';
import { MandatoryQuestionsComponent } from './mandatory-questions/mandatory-questions.component';
import { DynamicFormBuilderModule } from '../dynamic-form-builder/dynamic-form-builder.module';
import { OptionalQuestionsComponent } from './optional-questions/optional-questions.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { FooterUserComponent } from './common/footer-user/footer-user.component';

import { FlipModule } from 'ngx-flip';
import { ForumSurveyComponent } from './forum-survey/forum-survey.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { QuestionsCategoryComponent } from './questions-category/questions-category.component';
import { RedeemOfferComponent } from './common/popups/redeem-offer/redeem-offer.component';
import { OfferDetailsComponent } from './offer-details/offer-details.component';
import { ReferralUserListComponent } from './referral-user-list/referral-user-list.component';
import { OfferThankYouComponent } from './common/popups/offer-thank-you/offer-thank-you.component';
import { PaytmCashVoucherRedeemComponent } from './common/popups/paytm-cash-voucher-redeem/paytm-cash-voucher-redeem.component';
import { CategoryQuestionsComponent } from './category-questions/category-questions.component';
import { RouterModule } from '@angular/router';
import {ShowMoreComponent} from '../show-more/show-more.component';
import { DonateOfferComponent } from './common/popups/donate-offer/donate-offer.component';
import { LoaderComponent } from '../global/loader/loader.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LinkedinProfileComponent } from './linkedin-profile/linkedin-profile.component';
//import { NgxSpinnerModule } from 'ngx-spinner';
@NgModule({
  declarations: [OffersAndRewardsComponent, SurveysComponent, ReferAndEarnComponent, PollAndShareComponent, 
    FeedNotifcationComponent,ViewEditProfileComponent, DashboardComponent,
    LeftNavigationComponent,HeaderComponent, MandatoryQuestionsComponent, OptionalQuestionsComponent, 
    FooterUserComponent, ForumSurveyComponent, QuestionsCategoryComponent, RedeemOfferComponent, 
    OfferDetailsComponent,ReferralUserListComponent,FeedetailsModel, OfferThankYouComponent, 
    PaytmCashVoucherRedeemComponent, CategoryQuestionsComponent
    ,ShowMoreComponent,DonateOfferComponent,LoaderComponent, LinkedinProfileComponent],
  entryComponents: [RedeemOfferComponent,ReferralUserListComponent,FeedetailsModel,
    OfferThankYouComponent,PaytmCashVoucherRedeemComponent,DonateOfferComponent],
  imports: [
    
   // NgxSpinnerModule,
    RouterModule,
    FlipModule,
    BrowserAnimationsModule,
    PpBreadcrumbsModule,
    VirtualScrollerModule,
    ClickOutsideModule,
    HttpClientModule,
    BrowserModule,
    MatInputModule,MatOptionModule, MatSelectModule, MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule, 
    MatToolbarModule,
    MatButtonModule, 
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule    ,
    DynamicFormBuilderModule  , 
    ShareButtonsModule.withConfig({
      debug: false
    }),
    ShareIconsModule
  ]
})
export class UserDashboardModule { }
