import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RedeemOfferComponent } from "../common/popups/redeem-offer/redeem-offer.component";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { Router } from "@angular/router";
import { OfferThankYouComponent } from "../common/popups/offer-thank-you/offer-thank-you.component";
import { NgForm } from "@angular/forms";
import { PaytmCashVoucherRedeemComponent } from "../common/popups/paytm-cash-voucher-redeem/paytm-cash-voucher-redeem.component";
import { DonateOfferComponent } from "../common/popups/donate-offer/donate-offer.component";
import { DataService } from "../dashboard/dashboard.component";

export class OfferModel {
  OfferId: number;
  UtId: string;
  RewardName: string;
  Currency: string;
  Denomination: string;
  MinRange: number;
  MaxRange: number;
  Country: string;
  IsFavourite: number;
  AvailablePoints: number;
  ImagePath: string;
  Disclaimer: string;
}
@Component({
  selector: "app-offers-and-rewards",
  templateUrl: "./offers-and-rewards.component.html",
  styleUrls: ["./offers-and-rewards.component.scss"],
})
export class OffersAndRewardsComponent implements OnInit {
  ProfilePercentage = 40;
  PointProgress = 40;
  OfferData: any;
  flipDiv = -1;
  indx = 0;
  Activeindex: number = 0;
  ActiveDonationIndex: number = 0;
  OfferDetails: any;
  DonationDetails: Array<OfferModel> = [];
  totalAvailablePoints: any;
  responseMessage: any;
  notVerifiedMessage: any = "";
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidFormSubmitted = false;
  waitForDisplay = false;
  Submit = "Submit";
  mobileNumber: any;
  panelOpenState: boolean = true;
  quickPoints: number = 0;
  result: string;
  otherPointsToDonate: number = 0;
  pointRedeemMessage: any;

  constructor(
    public dialog: MatDialog,
    public _HttpServiceService: HttpServiceService,
    public _Router: Router,
    public _snackBar: MatSnackBar,
    private dataService: DataService
  ) {
    this.dataService
      .getProfilePercentageAndProfileDetails()
      .subscribe((responseList) => {
        this.pointRedeemMessage = responseList[0].data.pointRedeemMessage;
      });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  todayDate: Date = new Date();
  offerRedeem(offerId) {
    this._Router.navigate(["dashboard/offers-and-rewards/details/" + offerId]);
  }

  ngOnInit() {
    this.RefreshOfferData();
  }

  RefreshOfferData() {
    this.getOffersList("O", 1, 5);
    this.getDonationList("D", 1, 5);
  }
  animal: any;
  openDialog(
    OfferId: number,
    OfferTitle: string,
    OfferImage: string,
    PointsRequired: number,
    Favourite: boolean,
    TotalPoints: number,
    Eligible: boolean,
    ButtonVisible: boolean,
    Disclaimer: string,
    utId: any,
    currency: any,
    pointsRequried: number
  ): void {
    const dialogRef = this.dialog.open(RedeemOfferComponent, {
      width: "700px",
      data: {
        OfferId: OfferId,
        OfferTitle: OfferTitle,
        OfferImage: OfferImage,
        PointsRequired: PointsRequired,
        Favourite: Favourite,
        TotalPoints: TotalPoints,
        Eligible: Eligible,
        ButtonVisible: ButtonVisible,
        Disclaimer: Disclaimer,
        TotalAvailablePoints: this.totalAvailablePoints,
        utId: utId,
        currency: currency,
        pointsRequried: pointsRequried,
        pointRedeemMessage: this.pointRedeemMessage,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.RefreshOfferData();
    });
  }

  getOffersList(
    offerType: string = "O",
    pageindex: number = 1,
    pageSize: number = 5
  ) {
    this.Activeindex = this.Activeindex + pageindex;
    const url =
      "/api/Offer/OfferList?offerType=" +
      offerType +
      "&pageindex=1&pageSize=500";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.OfferDetails = response.data;
      this.totalAvailablePoints =
        this.OfferDetails.length != 0
          ? response.data[0].availablePoints
          : this.totalAvailablePoints;
    });
  }

  getDonationList(
    offerType: string = "D",
    pageindex: number = 1,
    pageSize: number = 5
  ) {
    this.ActiveDonationIndex = this.ActiveDonationIndex + pageindex;
    const url =
      "/api/Offer/OfferList?offerType=" +
      offerType +
      "&pageindex=1&pageSize=500";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.DonationDetails = response.data;
      this.totalAvailablePoints =
        this.DonationDetails.length != 0
          ? response.data[0].availablePoints
          : this.totalAvailablePoints;
    });
  }

  markFavourite(offerId: number, isFavourite: number, tangoFavtId: string) {
    const url = "/api/Offer/MarkOfferFavourite";
    let body = {
      isFavourite: isFavourite,
      tangoFavtId: tangoFavtId,
      offerId: offerId,
      userId: 0,
    };
    this._HttpServiceService.Post(url, body).subscribe((response) => {
      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        this.openSnackBar(JSON.stringify(response.message), "Succesfull");
      }
    });
    this.Activeindex = 0;
    this.ActiveDonationIndex = 0;
    this.getOffersList("O", 1, 5);
    this.getDonationList("D", 1, 5);
    //this.getOffersList(1,5);
  }

  onSubmit(f: NgForm) {
    this.waitForDisplay = true;
    this.isValidFormSubmitted = false;
    this.Submit = "Please wait";
    this.mobileNumber = JSON.stringify(f.value.paytm);
    if (f.invalid) {
      this.Submit = "Submit";
      this.waitForDisplay = false;
      return;
    }
    const url =
      "/api/Offer/GetPaytmUserStatus?PhoneNo=" +
      JSON.stringify(f.value.paytm) +
      "&paymentgatewayId=2";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.responseMessage = response.status;
      if (this.responseMessage) {
        this.notVerifiedMessage = "Verified and linked successfully.";
        this.openCashVoucherRedeem();
      } else {
        this.notVerifiedMessage = response.message;
      }
      this.isValidFormSubmitted = true;
      this.waitForDisplay = false;
      this.Submit = "Submit";
    });
  }

  openCashVoucherRedeem(): void {
    const dialogRef = this.dialog.open(PaytmCashVoucherRedeemComponent, {
      width: "700px",
      data: {
        mobileNumber: this.mobileNumber,
        totalAvailablePoints: this.totalAvailablePoints,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.RefreshOfferData();
    });
  }

  setQuickPoints(points: number) {
    this.quickPoints = points;
  }

  openDoantionDialog(
    OfferId: number,
    OfferTitle: string,
    OfferImage: string,
    PointsRequired: number,
    Favourite: boolean,
    TotalPoints: number,
    Eligible: boolean,
    ButtonVisible: boolean,
    Disclaimer: string
  ): void {
    if (this.otherPointsToDonate > 0) {
      this.quickPoints = this.otherPointsToDonate;
    }
    if (this.quickPoints == 0) {
      this.openSnackBar("Please select some points to donate.", "Successfull");
      return;
    }
    const dialogRef = this.dialog.open(DonateOfferComponent, {
      width: "700px",
      data: {
        OfferId: OfferId,
        OfferTitle: OfferTitle,
        OfferImage: OfferImage,
        PointsRequired: this.quickPoints,
        Favourite: Favourite,
        TotalPoints: TotalPoints,
        Eligible: Eligible,
        ButtonVisible: ButtonVisible,
        Disclaimer: Disclaimer,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialog.closeAll();
      this.RefreshOfferData();
    });
  }

  onKey(event) {
    this.otherPointsToDonate = event.target.value;
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }
}
