import { Component, OnInit, Input, Output, EventEmitter,ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { param } from 'jquery';

@Component({
  selector: 'app-category-questions',
  templateUrl: './category-questions.component.html',
  styleUrls: ['./category-questions.component.css']
})
export class CategoryQuestionsComponent implements OnInit {
  CategoryId: Params;
  @Output() callEvent = new EventEmitter<string>();

  GetQuestionCount(): void {
    this.callEvent.next('GetQuestionCount');
  }
  @Input() Id: string;

  public form: FormGroup;
  unsubcribe: any
  count:number=0;
  mySubscription: any;
  ProfilePercentage: any;
  
  constructor(private router: Router, private _HttpServiceService:HttpServiceService,private _snackBar:MatSnackBar,
    private route: ActivatedRoute) {

    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
};
this.router.events.subscribe((evt) => {
  if (evt instanceof NavigationEnd) {
     this.router.navigated = false;
     window.scrollTo(0, 90);
  }
});
  }
  ngOnInit() { 
    this.getDashboardData();
    this.getQuestions();
    
  }
  public fields: any[] = [];
  onUpload(e) { }
  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  QuestionData:any;
  getQuestions()
  {
    const url="/api/QuestionAnswer/GetCategoryQuestion?categoryId="+this.Id
    this._HttpServiceService.Get(url).subscribe(response => {
  if (response.data.question.type==='dropdown') {
    response.data.question.type='dropdown'
  }else if(response.data.question.type==='radiobutton'||response.data.question.type==='matrixradiobutton')
  {
    response.data.question.type='radio'
  }else if(response.data.question.type==='multiselect')
  {
    response.data.question.type='multiselect'
  }else if(response.data.question.type==='checkbox' || response.data.question.type==='matrixcheckBox')
  {
    response.data.question.type='checkbox'
  }
  else
  {
    response.data.question.type='text'
  }
  this.QuestionData=response.data.question;
  this.fields=[
    {
      type: response.data.question.type,
      name: 'key',
      label: response.data.question.profileQuestion,
      value: '',
      required: true,
      options: response.data.answer.map(e => {
        return {
          key: e.profileAnswerId,
          label: e.profileAnswer
        };
      })
    }]
      });
     
      this.fields=[ {
        type: '',
        name: 'key',
        label: '',
        value: '',
        required: true,
      }
    ]
  }
  answerId:any=0;
  SaveAnswer(x:any) { 
    this.answerId=x.key;
    const url = '/api/QuestionAnswer/SaveQuestionAnswer';
    this._HttpServiceService.Post(url, {
    profileQuestionId: this.QuestionData.profileQuestionId,
    profileAnswerId:String(x.key),
    profileAnswer:String(x.key),
    gpcid: this.QuestionData.gpcid,
    mandatory: true
  }).subscribe(response => {
    if(response.messageCode==1)
    {    
      this.getQuestions();
      this.openSnackBar(response.message, "Success");
      x=null;
  }
  else
  {
    this.openSnackBar(response.message, "Alert");
  }
  });
  this.GetQuestionCount();
  }
  getDashboardData()
  {
    const url = '/api/DashBoard/DashBoard';
    this._HttpServiceService.Get(url).subscribe(response => {
    
      this.ProfilePercentage=response.data.profileComplete;
      
    });
  }
  // setParam(param: Params): void {
  //   
  //   if(this.id=='0')
  //   {
  //   this.CategoryId=param.CategoryId
  // }else{
  //   this.CategoryId=param.CategoryIdInput
  // }

  //   this.getQuestions();
  // }
}
