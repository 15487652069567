import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ImageconverterService } from "src/app/global/imageconverter.service";
import { HttpServiceService } from "src/app/Services/common/http-service.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  todayDate: Date = new Date();
  HeaderNotification: any;
  Notification: any;
  showNotification: boolean;
  constructor(
    private _HttpServiceService: HttpServiceService,
    public _router: Router,
    private _ImageconverterService: ImageconverterService,
    private _snackBar: MatSnackBar
  ) {}
  openNotification(state: boolean) {
    this.showNotification = state;
  }
  ngOnInit() {
    //const url = '/api/User/Notifications';
    const urlCount = "/api/User/Notification/Count";
    this._HttpServiceService.Get(urlCount).subscribe((response) => {
      this.HeaderNotification = response.data;
    });
    // this._HttpServiceService.Get(url).subscribe(response => {
    //   this.Notification= response.data;
    // });
  }
  NotificationClick() {
    this._router.navigate(["dashboard/daily-feed"]);
  }
  RedirectToHome() {
    this._router.navigate(["dashboard"]);
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  signOut(): void {
    this.openSnackBar("Logged out", "Succesfull");
    localStorage.clear();
    this._router.navigate(["/login"]);
  }
  getImage(url) {
    this._ImageconverterService
      .getBase64ImageFromURL(url)
      .subscribe((base64data) => {
        // this is the image as dataUrl
        return "data:image/jpg;base64," + base64data;
      });
  }
  editProfile() {
    this._router.navigate(["dashboard/profile-settings"]);
  }
}
