import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IpServiceService {
  constructor(private http:HttpClient) { }
  
  public getIPAddress(): Observable<any> {

    return this.http.get("https://api.ipify.org/?format=json" , {})
        .pipe(
            
        )
  }
  getGEOLocation(ip) {
    // Update your api key to get from https://ipgeolocation.io
    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=84744c72669749feb2d5fca6ae2b4610&ip="+ip; 
      return this.http
            .get(url)
            .pipe(
           
            );
    } 
}

