import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ExtraActivitiesComponent } from 'src/app/global/extra-activities/extra-activities.component';

@Component({
  selector: 'app-why-join-us',
  templateUrl: './why-join-us.component.html',
  styleUrls: ['./why-join-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WhyJoinUsComponent implements OnInit {
  title = 'Surveys For Money in USA | Best Online Surveys Sites in India - Opinionest';
  description='Opinionest is the leading best online survey site. We go about our work and value your time. And help market researchers who bring reforms in the market and brands.'
  keywords='Join,JoinUs,Opinionest';



  constructor(private titleService: Title,private meta: Meta,private seoService:ExtraActivitiesComponent) { }

  ngOnInit() {
    this.seoService.createLinkForCanonicalURL();
    this.titleService.setTitle(this.title);
    this.meta.addTag({name: 'keywords', content: this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'og:title', content: this.title});
    this.meta.addTag({name: 'og:site_name', content: 'Opinionest'});
    this.meta.addTag({name: 'og:url', content: 'https://opinionest.com/'});
    this.meta.addTag({name: 'og:description', content:this.description});
    this.meta.addTag({name: 'og:type', content: 'website'});
    this.meta.addTag({name: 'og:image', content: 'https://opinionest.com/assets/images/logo-color.png'});
  }

}
