import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact-us-thank-you',
  templateUrl: './contact-us-thank-you.component.html',
  styleUrls: ['./contact-us-thank-you.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsThankYouComponent implements OnInit {
  getDetails() {
    
    this.referenceNumber= localStorage.getItem('referenceNumber');
    this.isExistingUser= localStorage.getItem('isExistingUser');
    // localStorage.setItem("referenceNumber", '');
    // localStorage.setItem("isExistingUser", '');
  }
  referenceNumber:any;
  isExistingUser:any;
  constructor(public route:ActivatedRoute) { 
  }

  ngOnInit() {  
    this.getDetails();
  }

}
