import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from "angularx-social-login";
import * as firebase from "firebase/app";
import { customValidations } from "src/app/global/shared/validations.enum";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { IpServiceService } from "src/app/Services/common/ip-service.service";
import { SharedService } from "src/app/Services/common/SharedService";
import { PrivacyPolicyComponent } from "src/app/web-view/privacy-policy/privacy-policy.component";
import { TermsofServicesComponent } from "src/app/web-view/termsof-services/termsof-services.component";

declare function setLanguage();
export class RegisterModel {
  constructor(
    public fullName: string = "",
    public emailAddress: string = "",
    public password: string = "",
    public policyStatus: number = 0,
    public macAddress: string = "",
    public ipAddress: string = "",
    public partnerParams: string = "",
    public mobileNo: string = "",
    public countrCode: string = ""
  ) { }
}
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {
  [x: string]: any;
  ip: any;
  registerForm: FormGroup;
  RegisterModel = new RegisterModel();
  wechatConfig: any;
  selfDeclaration: any = false;
  urlParam: any;
  numberRegEx = /\-?\d*\.?\d{1,16}/;
  key: string = "partnerKey";
  YOUR_SITE_KEY = "6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ";
  buttons = {
    register: { progress: false, buttonName: "Create my Account" },
  };
  title = "Register to Earn Money | Opinionest";
  description =
    "Paid online surveys with Opinionest! Earn money for each paid survey you complete! Sign up for free online surveys today!";
  keywords = "Opinionest, online paid surveys,Register";

  facebookId: any;
  gmailId: any;
  firstName: any;
  lastName: any;
  countryId: any = 0;
  constructor(
    public _router: Router,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _HttpServiceService: HttpServiceService,
    public _IpServiceService: IpServiceService,
    public afAuth: AngularFireAuth,
    private titleService: Title,
    private meta: Meta,
    private authService: SocialAuthService,
    private metaService: SharedService
  ) {
    this.getIP();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  PolicyStatus: boolean = false;
  Termes: boolean = false;
  passMatch: boolean = false;

  openPolicy() {
    const dialogRef = this.dialog.open(PrivacyPolicyComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.PolicyStatus = result;
    });
  }
  openTermandConditions() {
    const dialogRef = this.dialog.open(TermsofServicesComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.Termes = result;
    });
  }
  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.titleService.setTitle(this.title);
    this.meta.addTag({ name: "keywords", content: this.keywords });
    this.meta.addTag({ name: "description", content: this.description });
    this.meta.addTag({ name: "og:title", content: this.title });
    this.meta.addTag({ name: "og:site_name", content: "Opinionest" });
    this.meta.addTag({ name: "og:url", content: "https://opinionest.com/" });
    this.meta.addTag({ name: "og:description", content: this.description });
    this.meta.addTag({ name: "og:type", content: "website" });
    this.meta.addTag({
      name: "og:image",
      content: "https://opinionest.com/assets/images/logo-color.png",
    });
    //this.seoService.createLinkForCanonicalURL();
    this.urlParam = window.location.href.split("?");

    if (this.urlParam[1] != "" && this.urlParam[1] != undefined) {
      localStorage.setItem(this.key, this.urlParam[1]);
    }

    setLanguage();
    this.YOUR_SITE_KEY = "6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ";
    this.Termes = false;
    this.wechatConfig = {
      appid: "string",
      redirectUri: "string",
      scope: "string",
      loginType: "string",
      state: "string",
      selfRedirect: true,
      style: "string",
      href: "string",
    };

    this.createregisterForm();
  }

  createregisterForm() {
    this.registerForm = this._formBuilder.group(
      {
        mobileNo: [
          "",
          [
            Validators.required,
            Validators.pattern(this.numberRegEx),
            Validators.maxLength(15),
            Validators.minLength(10),
          ],
        ],

        email: [
          "",
          [
            Validators.required,
            Validators.maxLength(500),
            Validators.pattern(customValidations.email),
          ],
        ],

        username: ["", [Validators.required]],
        password: ["", [Validators.required, Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)]],
        repassword: ["", [Validators.required]],
        terms: [false, [Validators.required]],
        recaptcha: ["", [Validators.required]],
      },
      { validator: this.checkPasswords }
    );
  }
  showSpinner: any;
  Register: any = "Register";
  onSubmit(data, from) {
    if (this.Register == "Please wait..") {
      return;
    }
    this.Register = "Please wait..";
    this.urlParam = window.location.href.split("?");
    if (this.urlParam[1] != "" && this.urlParam[1] != undefined) {
      localStorage.setItem(this.key, this.urlParam[1]);
    }

    if (from != "G") {
      const req_data = this.registerForm.value;
      this.updateModel(req_data);
    }

    if (data != "" && from == "G") {
      this.RegisterModel.emailAddress = data.email;
      this.RegisterModel.fullName = data.name;
      this.RegisterModel.policyStatus = 1;
      this.gmailId = data.email;
      // this.firstName=data.givenName?data.givenName:data.name?.split(' ')[0];
      // this.lastName=data.familyName?data.familyName:data.name?.split(' ').length>1?data.name.split(' ')[1]:'';
      this.selfDeclaration = true;
    } else if (from == "F") {
      this.RegisterModel.emailAddress = data.email;
      this.RegisterModel.fullName = data.name;

      // this.firstName=data.name.split(' ')[0];

      // this.firstName=data.name.split(' ').length>1?data.name.split(' ')[1]:'';

      this.facebookId = data?.email;
      this.RegisterModel.policyStatus = 1;
      this.selfDeclaration = true;
    }
    if (this.selfDeclaration == false) {
      if (from == "G" || from == "F") {
        this.openSnackBar(
          "Please accept our terms of service and privacy policy",
          "Alert"
        );
      } else {
        alert("Please accept our terms of service and privacy policy");
      }
      this.Register = "Register";
      return;
    }
    this.RegisterModel.macAddress = "";
    if (
      localStorage.getItem(this.key) != "" &&
      localStorage.getItem(this.key) != undefined
    ) {
      this.RegisterModel.partnerParams = localStorage
        .getItem(this.key)
        .replace("&", "#emp#");
    } else {
      this.RegisterModel.partnerParams = "";
    }

    if (from == "G" || from == "F") {
      const urlLogin = "/api/Login";
      this.gmailId = data?.email;
      this._HttpServiceService
        .PostWithoutToken(urlLogin, {
          emailId: this.emailId,
          password: this.password,
          isSocialLogin: this.isSocialLogin,
          facebookId: this.facebookId,
          gmailId: this.gmailId,
          firstName: this.firstName,
          lastName: this.lastName,
          ipAddress: this.ipAddress,
          countryId: this.countrCode,
        })
        .subscribe((response) => {
          if (parseInt(JSON.stringify(response.messageCode)) === 1) {
            if (this._HttpServiceService.setLoginAuth(response)) {
              const url = this._router.serializeUrl(
                this._router.createUrlTree(["dashboard"])
              );

              window.open(url, "_self");
            }
          }
        });
      return;
    }
    this.RegisterModel.ipAddress = this.ipAddress;
    const url = "/api/Registation/Registation";
    this._HttpServiceService
      .PostWithoutToken(url, this.RegisterModel)
      .subscribe((response) => {
        this.showloader = false;
        this.Register = "Register";
        if (response.messageCode == 1) {
          this.openSnackBar(response.message, "Successfull");
          localStorage.removeItem(this.key);
          localStorage.setItem(
            "registerEmail",
            this.RegisterModel.emailAddress
          );
          localStorage.setItem("phone", this.RegisterModel.mobileNo);
          this._router.navigate(["login/otp", this.RegisterModel.emailAddress]);
        } else {
          if (from == "G" || from == "F") {
            alert(response.message);
          } else {
            this.openSnackBar(response.message, "Alert");
          }
        }
      });
  }
  showResponse() {
    this.setValue("captcha", "captchFilled");
  }
  captcha = "";
  setValue(formKey, val) {
    this.registerForm.get(formKey).setValue(val);
  }
  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.repassword.value;
    if (pass === confirmPass) {
      return true;
    } else {
      group.controls.repassword.setErrors({ notSame: true });
      return false;
    }
  }

  Socialregister(x) { }
  getIP() {
    this._IpServiceService.getIPAddress().subscribe((response) => {
      this.ipAddress = response.ip;
      this.getCountryFlag(this.ipAddress);
    });
  }
  updateModel(formData) {
    this.RegisterModel.emailAddress = formData.email;
    this.RegisterModel.fullName = formData.username;
    this.RegisterModel.policyStatus = 1;
    this.RegisterModel.ipAddress = this.ipAddress;
    this.RegisterModel.macAddress = "";
    this.RegisterModel.password = formData.password;
    this.RegisterModel.mobileNo = formData.mobileNo;
    this.RegisterModel.countrCode = this.countrCode;
  }
  showloader: boolean = false;
  registerWithGoogle() {
    firebase.auth().signOut();
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.user = user;
      this.loggedIn = user != null;
      this.firstName = user.firstName;
      this.lastName = user.lastName;

      if (user.email != null) {
        this.onSubmit(user, "G");
      }
    });
  }

  registerWithFB() {
    firebase.auth().signOut();
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.user = user;
      this.loggedIn = user != null;
      if (user.email != null) {
        this.onSubmit(user, "G");
      }
    });
  }
  setDeclaration(event: any) {
    if (event.checked) {
      this.selfDeclaration = true;
    } else {
      this.selfDeclaration = false;
    }
  }
  handleExpire() { }
  handleReset() { }
  handleLoad() { }
  handleSuccess(x) { }

  getCountryFlag(IpAddress) {
    const urlLogin = "/api/Registation/CountryCode?Ip=" + IpAddress;
    this._HttpServiceService.GetWithoutToken(urlLogin).subscribe((response) => {
      this.countryData = response.data;
      this.countrCode = this.countryData.countryCode;
    });
  }
}
