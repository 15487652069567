import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';

@Component({
  selector: 'app-offer-thank-you',
  templateUrl: './offer-thank-you.component.html',
  styleUrls: ['./offer-thank-you.component.scss']
})
export class OfferThankYouComponent implements OnInit {
  OfferData: { OfferImage: string; OfferTitle: string; OfferDetails: string; PointsRequired: string; Favourite: boolean; 
    TotalPoints: number; Eligible: boolean; OfferId: number; Result: any,Disclaimer: string}[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any
  ,public _HttpServiceService:HttpServiceService,
  public _snackBar: MatSnackBar
  ,public dialog: MatDialog) { }

  ngOnInit() {
    this.OfferData = [
      {
       OfferImage: this.data.OfferImage,
       OfferTitle:this.data.OfferTitle,
       OfferDetails: this.data.OfferDetails,
       PointsRequired:this.data.PointsRequired,
       Favourite:this.data.Favourite,
       TotalPoints:this.data.TotalPoints,
       Eligible:this.data.Eligible,
       OfferId: this.data.OfferId,
       Result: this.data.Result,
       Disclaimer: this.data.Disclaimer
      }
     ];
  }
  closeDialog(){
    this.dialog.closeAll();
  }
}
