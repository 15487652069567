import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { customValidations } from 'src/app/global/shared/validations.enum';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { IpServiceService } from 'src/app/Services/common/ip-service.service';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RecoverPasswordComponent implements OnInit {
  YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
  attemptCount: any=0;
  recoverySent: boolean=false;
  constructor(  public _router: Router,
    private _formBuilder: FormBuilder,private apiService: ApiService,
    public dialog: MatDialog,private ip:IpServiceService,
    private _snackBar: MatSnackBar,private _HttpServiceService:HttpServiceService) { }
  showSpinner:any;
  recoveryForm: FormGroup;
  
  ngOnInit() {
    this.YOUR_SITE_KEY= '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
    this.createrecoveryForm();

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
  createrecoveryForm() {
    this.recoveryForm = this._formBuilder.group({
      email: ['', [Validators.required,Validators.maxLength(500),
        Validators.pattern(customValidations.email)]],
        recaptcha: ['', Validators.required]
      //captcha:  ['', [Validators.required]]
    });
    
  }

  showResponse() {
    this.setValue('captcha', 'captchFilled');
  }
  setValue(formKey, val) {
    this.recoveryForm.get(formKey).setValue(val);
  }
  submit:boolean=false;
  onSubmit()
  {
  
    if(this.recoveryForm.invalid)
    {
      return;
    }
    this.submit=true;
    let key = 'LogedIn';
    this.attemptCount=this.attemptCount+1;
    const url = '/api/Password/Recover';
    this._HttpServiceService.PostWithoutToken(url,{'emailId': this.recoveryForm.value.email}).subscribe(response => {
      this.submit=false;
    if(parseInt(JSON.stringify(response.messageCode))===1)
    {
      this.openSnackBar(JSON.stringify(response.message), "Succesfull");
      localStorage.setItem("registerEmail",this.recoveryForm.value.email);
      this._router.navigate(['login/reset',this.recoveryForm.value.email]);
    }
    else{
      this.openSnackBar(JSON.stringify(response.message), "Alert");
    }
     
    },
    (error:any)=>
    {
      this.recoverySent=true;
      this.openSnackBar( JSON.stringify(error.message), "Error");
      this.submit=false;
    }
    );
 }
 
 handleExpire()
 {}
 handleReset()
 {}
 handleLoad()
 {}
 handleSuccess(x)
 {}
}

