import { Component, OnInit } from '@angular/core';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';

@Component({
  selector: 'app-tango',
  templateUrl: './tango.component.html',
  styleUrls: ['./tango.component.css'],
  
})
export class TangoComponent implements OnInit {
header:any;
Api:any;



  constructor( private _HttpServiceService:HttpServiceService) { }

  ngOnInit() {
    this.onSubmit();
  }
  onSubmit()
  {
    
    this._HttpServiceService.GetTango(this.Api)
        .subscribe(
          (response) => {
            if (response) {
             console.log(response);
             alert('See response');
            }
          },
          err => {
            alert('See Network');
          });
  }
}
