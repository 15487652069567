import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class ResetModel {
  constructor(
    public password: string = '',
    public repassword: string = '',
    public otp: string = '',
  ) { }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ResetPasswordComponent implements OnInit {
  email: string;
  code: string;
  resetForm: FormGroup;
  ResetModel = new ResetModel();
  constructor(public route: ActivatedRoute,private _formBuilder: FormBuilder,
    public _router: Router,private _HttpServiceService: HttpServiceService,private _snackBar: MatSnackBar) { }
  YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
  passMatch: boolean = false
  ngOnInit() {
    this.YOUR_SITE_KEY = '6LdHcYcUAAAAADPyy7-Os-PR81Yj4pe8ZTNWIiFQ';
    this.email =localStorage.getItem("registerEmail");
    this.createresetForm();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  createresetForm() {
    this.resetForm = this._formBuilder.group({
      password: ['', [Validators.required]],
      repassword: ['', [Validators.required]],
      otp: ['', [Validators.required]]
    }, { validator: this.checkPasswords });
  }
  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.repassword.value;
    if (pass === confirmPass) {
      return true;
    }
    else {
      group.controls.repassword.setErrors({ notSame: true });
      return false;
    }

  }
  showSpinner: any;
  updateModel(formData) {
    this.ResetModel.password = formData.password;
    this.ResetModel.repassword = formData.repassword;
    this.ResetModel.otp = formData.otp;
  }
  submit:boolean=false;
  onSubmit() {
    this.submit=true;
    const req_data = this.resetForm.value;
    this.updateModel(req_data);
    let body = {
      emailId: this.email,
      password: this.ResetModel.password,
      otp: this.ResetModel.otp
    }
    
    const url = '/api/Registation/ResetPassword';
    this._HttpServiceService.PostWithoutToken(url, body).subscribe(response => {
      this.submit=false;
      if (response.messageCode == 1) {
        this.openSnackBar(response.message, "Successfull");
        this._router.navigate(['login']);
      }
      else {
        this.openSnackBar(response.message, "Alert");
      }
    });
  }
}
