import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-web",
  templateUrl: "./header-web.component.html",
  styleUrls: ["./header-web.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderWebComponent implements OnInit {
  @Input() activeIndex: string = "1";
  loginbuttonText: string = "Login";
  loginbuttonredirect: string = "/login";
  constructor(
    private _HttpServiceService: HttpServiceService,
    private _router: Router
  ) {}
  homeActive: boolean = true;
  aboutActive: boolean = false;
  HowWorksActive: boolean = false;
  blogActive: boolean = false;
  contactActive: boolean = false;
  pageActive: number;
  ngOnInit() {
    if (this._HttpServiceService.isLoggedIn()) {
      this.loginbuttonText = "View Dashboard";
      this.loginbuttonredirect = "/dashboard";
    }
  }
  redirecttoRespectivePage() {
    this._router.navigateByUrl(this.loginbuttonredirect);
  }
  RedirectPage(page: number) {
    document.body.classList.remove("mob_menu_open");
    if (page == 1) {
      this._router.navigateByUrl("/");
    }
    if (page == 2) {
      this._router.navigateByUrl("/about-us");
    }
    if (page == 3) {
      this._router.navigateByUrl("/how-it-works");
    }
    if (page == 4) {
      this._router.navigateByUrl("/blogs");
    }
    if (page == 5) {
      this._router.navigateByUrl("/contact-us");
    }
  }

  mobileMenuToggle() {
    document.body.classList.toggle("mob_menu_open");
  }
}
