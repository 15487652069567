import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { ExtraActivitiesComponent } from 'src/app/global/extra-activities/extra-activities.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-termsof-services',
  templateUrl: './termsof-services.component.html',
  styleUrls: ['./termsof-services.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TermsofServicesComponent implements OnInit {
  title = 'Terms and Conditions | Opinionest';
  description='We welcome you to the Opinionest website. Here, by accessing, browsing, or using this website, you acknowledge that you have read, understood, and agreed to be bound by these Terms of Use.';
  keywords='';

  constructor(private seoService:ExtraActivitiesComponent, private titleService: Title,private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name: 'keywords', content: this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'og:title', content: this.title});
    this.meta.addTag({name: 'og:site_name', content: 'Opinionest'});
    this.meta.addTag({name: 'og:url', content: 'https://opinionest.com/'});
    this.meta.addTag({name: 'og:description', content:this.description});
    this.meta.addTag({name: 'og:type', content: 'website'});
    this.meta.addTag({name: 'og:image', content: 'https://opinionest.com/assets/images/logo-color.png'});
    this.seoService.createLinkForCanonicalURL();
  }

}
