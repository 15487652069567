import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ChatDialogComponent } from 'src/app/chat/chat-dialog/chat-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-footer-user',
  templateUrl: './footer-user.component.html',
  styleUrls: ['./footer-user.component.css']
})
export class FooterUserComponent implements OnInit {

  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
  }
  openBottomSheet(): void {

    this._bottomSheet.open(ChatDialogComponent);
  }

}
