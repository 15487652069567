import { Component, OnInit, Input,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';

@Component({
  selector: 'app-web-left-navigation',
  templateUrl: './web-left-navigation.component.html',
  styleUrls: ['./web-left-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebLeftNavigationComponent implements OnInit {
  @Input() menuIndex: string;
  constructor(private _router:Router,private _iHttpService:HttpServiceService) { }
  ngOnInit() {
  }
  navigate(navigateto)
  {
    if(navigateto===1)
    {
      this._router.navigate(['dashboard'])
    }
    else if(navigateto===2){
    this._router.navigate(['dashboard/offers-and-rewards'])
    }
    else if(navigateto===3){
      this._router.navigate(['dashboard/forum'])
      }
      else if(navigateto===4){
        this._router.navigate(['dashboard/refer-and-earn'])
        }
        else if(navigateto===5){
          this._router.navigate(['dashboard/forum'])
          }
      else if(navigateto===6){
        this._router.navigate(['dashboard/daily-feed'])
        }
        else if(navigateto===7){
          this._router.navigate(['blogs'])
          }

  }
}
