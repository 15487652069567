import { OnInit, HostListener } from "@angular/core";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
export class referralsModel {
  name: string;
  shortName: string;
  points: string;
  status: string;
  sentDate: string;
}
@Component({
  selector: "app-referral-user-list",
  templateUrl: "./referral-user-list.component.html",
  styleUrls: ["./referral-user-list.component.scss"],
})
export class ReferralUserListComponent implements OnInit {
  Activeindex: number = 0;

  MyReferrals: Array<referralsModel> = [];
  CopyMyReferrals: Array<referralsModel> = [];
  totalreferals: number;
  referalPoint: any;
  constructor(
    private _HttpServiceService: HttpServiceService,
    public dialog: MatDialog
  ) {
    this.bindTable(1, 10);
  }

  ngOnInit() {
    const url =
      "/api/Home/ReferralList?pageindex=" + this.Activeindex + "&pageSize=500";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.totalreferals = this.MyReferrals.length;
      this.referalPoint = response.totalPoint;
    });
  }

  bindTable(pageindex: number = 1, pageSize: number = 5) {
    this.Activeindex = this.Activeindex + pageindex;
    const url =
      "/api/Home/ReferralList?pageindex=" + this.Activeindex + "&pageSize=50";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.MyReferrals = response.data;
    });
  }
  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    var viewportHeight = document.documentElement.clientHeight;
    var documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.body.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

    var maxScrollY = documentHeight - viewportHeight;
    if (verticalOffset == maxScrollY) {
      this.bindTable(1, 5);
    } else if (verticalOffset == 0) {
      this.bindTable(-1, 5);
    }
  }

  toggleNotificationBar() {
    document.body.classList.remove("open_notiffication_bar");
  }
}
