import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private http:HttpClient,@Inject(DOCUMENT) private dom) { }
  
  private data = {};  
  
  setData(option, value) {  
    this.data[option] = value;  
  }  
  getData() {  
    return this.data;  
  }  
  createCanonicalURL() {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
 }
}

