import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'checkbox',
    styles: ['.inputTextSearch { border: 1px solid gray; }'],
    template: `
      <div [formGroup]="form">
        <div [formGroupName]="field.name" >
          <div  #container class="container" *ngFor="let opt of data" class="form-check form-check">
          <input matInput *ngIf="showText"  placeholder="Search.." autofocus  type="text"  class="inputTextSearch custSearch"  (keyup)="selectdrop($event) ;container.scrollTop = 0" >
          <label class="form-check-label">
             <input [formControlName]="opt.key" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
             {{opt.label}}</label>
          </div>
        </div>

      </div> 
    `
})
export class CheckBoxComponent {
    @Input() field:any = {};
    @Input() form:FormGroup;
  oldLable: any='';
  data: any[];
  showText: boolean=false;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    ngOnInit() { 
      this.binddropdown("");
      if(this.field.options.length>4)
      { this.showText=true;}
    }
    selectdrop(event)
    {
      this.binddropdown(event.target.value);
    }
    scrollToTop(el) {
    }
    binddropdown(lable)
    {
      if(lable===this.oldLable && this.oldLable!='')
      {
        return;
      }
      this.data=[];
       this.field.options.forEach((item, index)=>
       {
         if (item.label.toLowerCase().includes(lable.toLowerCase())) {
         this.data.push({"key":item.key,"label":item.label});
         }
         else if(lable=='')
         {
          this.data.push({"key":item.key,"label":item.label});
         }
       });
       this.oldLable=lable;

    }
  }