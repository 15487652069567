import { Component, OnInit, Inject ,ViewEncapsulation} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';

@Component({
  selector: 'app-extra-activities',
  templateUrl: './extra-activities.component.html',
  styleUrls: ['./extra-activities.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExtraActivitiesComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document, public route: ActivatedRoute
    , private _HttpServiceService: HttpServiceService, @Inject(DOCUMENT) private doc) {
   
     }
  param: any;
  responseData: any;
  ngOnInit() {
    this.resolve();
  }
  postData() {
    //  this.param = window.location.href;
    //  console.log(this.param.split('?'));
    
    this.route.queryParamMap.subscribe(queryParams => {
      this.param = queryParams.get("cosur")
    })
    
    const url = '/api/extraactivities/mailopen?cosur='+this.param;
    this._HttpServiceService.GetWithoutToken(url).subscribe(response => {
      this.responseData = response.data;
      this.resolve();
    });
  }
  resolve(){
    window.location.href = 'https://www.opinionest.com/assets/images/logo-color.png';
    return false;
  }
  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);
 }

}
