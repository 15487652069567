import { WINDOW } from "@ng-toolkit/universal";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PpBreadcrumbsService, Breadcrumb } from "pp-breadcrumbs";
// import { MessagingService } from './Services/messaging.service';
// import { NgxSpinnerService } from 'ngx-spinner';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { Title, Meta } from "@angular/platform-browser";
import { SharedService } from "./Services/common/SharedService";
import { DeviceDetectorService } from "ngx-device-detector";
declare function setLanguage();
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  message;
  deviceInfo: any;
  constructor(
    @Inject(WINDOW) private window: Window,
    private translate: TranslateService,
    private breadcrumbsService: PpBreadcrumbsService,
    private router: Router,
    private meta: Meta,
    private metaService: SharedService,
    private deviceService: DeviceDetectorService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      //this.spinner.show();
    }
    if (event instanceof NavigationEnd) {
      //this.spinner.hide();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      //this.spinner.hide();
    }
    if (event instanceof NavigationError) {
      //this.spinner.hide();
    }
  }
  ngOnInit() {
    // this.meta.addTag({ name: "viewport", content: "width=1024" });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    // if(isMobile || isTablet)
    // {
    //   window.location.href = "https://m.opinionest.com";
    // }
    this.metaService.createCanonicalURL();
    this.breadcrumbsService.postProcess = (breadcrumbs: Breadcrumb[]) => {
      if (breadcrumbs.length) {
        breadcrumbs.unshift({ text: "Home", path: "" });
      }
      return breadcrumbs;
    };

    // const browserLang = this.translate.getBrowserLang() ;
    // if (browserLang ==='en'|| browserLang ==='tl-PH' || browserLang ==='en-PH'||browserLang ==='PH'
    // || browserLang ==='vi' || browserLang ==='vi-VN') {
    //   this.switchLanguage(browserLang);
    // }
    // //this.spinner.hide();
  }
  switchLanguage(language: string) {
    this.translate.use(language);
  }

  onActivate(event) {
    if (window && window.scroll) {
      window.scroll(0, 0);
    }
  }
}
