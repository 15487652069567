import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { faFacebook, faGoogle, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import  { faStar} from '@fortawesome/free-solid-svg-icons'
@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.component.html',
  styleUrls: ['./footer-web.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterWebComponent implements OnInit {
  date:any;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;
  faStar = faStar;
  faGoogle = faGoogle;
  constructor(private _bottomSheet: MatBottomSheet) { 

    this.date= (new Date()).getFullYear() +'-'+ ((new Date()).getFullYear()+1)
  }

  ngOnInit() {
   
  }
  Chatbot:boolean=false;
  openBottomSheet(): void {
    this.Chatbot=true;
    // this._bottomSheet.open(ChatDialogComponent);
  }
  CloseBottomSheet(): void {
    this.Chatbot=false;
    // this._bottomSheet.open(ChatDialogComponent);
  }
}
