import { Component, OnInit, Input,ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoComponent implements OnInit {
  safeSrc: SafeResourceUrl;
  @Input() url:string;
  constructor(private _sanitizer:DomSanitizer) {
       }

  ngOnInit() {
    
    this.safeSrc =  this._sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

}
