import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { customValidations } from "src/app/global/shared/validations.enum";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { IpServiceService } from "src/app/Services/common/ip-service.service";
import { TermsofServicesComponent } from "src/app/web-view/termsof-services/termsof-services.component";

export class Country {
  countryCode: string;
  countryId: number = 0;
  countryName: string;
}
@Component({
  selector: "app-start-dashboard",
  templateUrl: "./start-dashboard.component.html",
  styleUrls: ["./start-dashboard.component.scss"],
})
export class StartDashboardComponent implements OnInit {
  @ViewChild("myDatepicker") picker;
  FormShow: any = 1;
  isLinear = false;
  firstFormGroup: FormGroup;
  dataSource: Country[];
  SecondGroup: FormGroup;
  switchFirst: boolean = true;
  switchSecond: boolean = false;
  switchThird: boolean = false;
  switchMobile;
  clicked: boolean = false;
  searchCtrl = "";
  pinCode = "";
  dob = "";
  gender = "";
  filterdData: number;
  countryId: number = 0;
  OTPForm: FormGroup;
  MobileForm: FormGroup;
  countrCode: any;
  mobile: string;
  otpClicked: boolean = false;
  submitClicked: boolean = false;
  email: any;
  countryData: any;
  countrCodeOtp: string;
  constructor(
    private _formBuilder: FormBuilder,
    private _HttpServiceService: HttpServiceService,
    public _snackBar: MatSnackBar,
    public _router: Router,
    public _IpServiceService: IpServiceService,
    private dialogRef: MatDialogRef<StartDashboardComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { switchMobile: string; profileUpdated: boolean }
  ) {}
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  ngOnInit() {
    this.getProfileDetails();
    this.switchMobile = this.data.switchMobile;
    if (this.switchMobile) {
      this.createOTPForm();
      this.createMobileForm();
    }
    this.getIP();
    this.bindCountry();
    this.pinCode = "";
    this.firstFormGroup = this._formBuilder.group(
      {
        term: ["", [Validators.required]],
        policy: ["", [Validators.required]],
      },
      { validator: checkCheckbox }
    );
    function checkCheckbox(c: AbstractControl) {
      if (c.get("term").value == false) {
        return false;
      } else if (c.get("policy").value == false) {
        return false;
      } else return true;
    }
    this.SecondGroup = this._formBuilder.group({
      countryId: [],
      pinCode: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
    });
  }
  bindCountry() {
    const url = "/api/Common/Country";
    this._HttpServiceService.GetWithoutToken(url).subscribe((response) => {
      this.dataSource = response.data;
    });
  }

  onback() {
    this.FormShow--;
  }
  public birthdate: Date;
  public age: number;
  Save() {
    if (this.dob) {
      var timeDiff = Math.abs(Date.now() - new Date(this.dob).getTime());
      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      if (this.age < 13) {
        this.openSnackBar("Age should be greater then 12 years", "Alert");
        return;
      }
    } else {
      this.openSnackBar("Age should be greater then 12 years", "Alert");
      return;
    }

    const url = "/api/User/ConsentPrivacy";
    let body = {
      userID: 0,
      tearm: 1,
      policy: 1,
      dob: this.dob,
      gender: this.gender,
      pinCode: this.pinCode,
      countryId: this.countryId,
    };

    this._HttpServiceService.Post(url, body).subscribe((response) => {
      if (parseInt(JSON.stringify(response.messageCode)) === 1) {
        this.openSnackBar(JSON.stringify(response.message), "Succesfull");
        this.dialogRef.close();
        this._router.navigate(["/dashboard"]);
      } else {
        this.openSnackBar(JSON.stringify(response.message), "Alert");
      }
    });
  }

  switchNext(value) {
    if (value == "household") {
      this.switchFirst = false;
      this.switchSecond = true;
    }
    if (value == "privacy") {
      if (
        this.firstFormGroup.invalid ||
        !this.firstFormGroup.get("term").value
      ) {
        this.openSnackBar("Please accept terms and conditions", "Alert");
        return;
      } else if (!this.firstFormGroup.get("policy").value) {
        this.openSnackBar("Please accept policy", "Alert");
        return;
      }
      this.switchSecond = false;
      this.switchThird = true;
    }
    if (value == "submit") {
      this.Save();
    }
  }
  getIP() {
    this._IpServiceService.getIPAddress().subscribe((response) => {
      this.getCountryFlag(response.ip);
      this._IpServiceService
        .getGEOLocation(response.ip)
        .subscribe((responseData) => {
          this.getDimensionsByFilter(responseData);
        });
    });
  }
  createOTPForm() {
    this.OTPForm = this._formBuilder.group({
      otp: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern(customValidations.NumericOnly),
        ],
      ],
    });
  }
  createMobileForm() {
    this.MobileForm = this._formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.minLength(9),
          Validators.pattern(customValidations.mobile),
        ],
      ],
    });
  }
  resend() {
    this.mobile = this.MobileForm.get("mobile").value;
    this.countrCodeOtp = encodeURIComponent(
      this.countrCode == undefined || this.countrCode == "+"
        ? "+91"
        : this.countrCode
    );
    this.clicked = true;
    const urlLogin =
      "/api/Password/mobileOtp?" +
      "countryCode=" +
      this.countrCodeOtp +
      "&mobile=" +
      this.mobile;
    this._HttpServiceService.Get(urlLogin).subscribe((response) => {
      if (parseInt(response.messageCode) === 1) {
        this.openSnackBar(response.message, "Successful");
        this.otpClicked = true;
        this.clicked = false;
      } else {
        this.otpClicked = false;
        this.clicked = false;
        this.openSnackBar(response.message, "Alert");
      }
    });
  }
  getDimensionsByFilter(id) {
    this.countryId = this.dataSource.find(
      (x) => x.countryCode === id.country_code2
    ).countryId;
  }
  openTermandConditions() {
    const dialogRef = this.dialog.open(TermsofServicesComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }
  openInNewWindow(urlredirect) {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this._router.serializeUrl(
      this._router.createUrlTree([urlredirect])
    );

    window.open(url, "_blank");
    return false;
  }
  reOpenCalendar() {
    this.picker.open();
    // let self = this;
    //    setTimeout(
    //        ()=>{
    //            self.picker.open();
    //        },
    //        50
    //    );
  }
  onSubmit() {
    this.submitClicked = true;
    this.clicked = true;
    const url = "/api/Registation/OtpVerifyMobile";
    this._HttpServiceService
      .Post(url, {
        emailId: this.email,
        otp: this.OTPForm.get("otp").value,
        emailOtp: 0,
      })
      .subscribe(
        (response) => {
          if (parseInt(response.messageCode) === 1) {
            this.dialogRef.close();
            this._router.navigate(["dashboard/profile-settings"]);
          } else {
            this.openSnackBar(response.message, "Alert");
          }
          this.submitClicked = false;
          this.clicked = false;
        },
        (error: any) => {
          this._router.navigate(["login/otp"]);
          this.clicked = false;
          this.openSnackBar(error, "Alert");
        }
      );
  }
  getProfileDetails() {
    const url = "/api/User/GetProfile";
    this._HttpServiceService.Get(url).subscribe(
      (response) => {
        if (response) {
          this.email = response.data.emailAddress;
        }
      },
      (err) => {}
    );
  }
  getCountryFlag(IpAddress) {
    const urlLogin = "/api/Registation/CountryCode?Ip=" + IpAddress;
    this._HttpServiceService.GetWithoutToken(urlLogin).subscribe((response) => {
      this.countryData = response.data;
      this.countrCode = this.countryData.countryCode;
    });
  }
}
