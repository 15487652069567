import { Component, OnInit, Inject ,ViewEncapsulation} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paytm-cash-voucher-redeem',
  templateUrl: './paytm-cash-voucher-redeem.component.html',
  styleUrls: ['./paytm-cash-voucher-redeem.component.scss']
})
export class PaytmCashVoucherRedeemComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public _HttpServiceService: HttpServiceService
  ,public dialog: MatDialog,private _snackBar: MatSnackBar,private _router:Router) { }
  responseMessage: any;
  successMessage: any;
  errorMessage: any;
  mobileNumber: any;
  searchValue: any;
  searchValueFromEvent: any;
  pointsValue: any;
  convertedPoints: any;
  totalAvailablePoints: any;
  isDisabled: any = false;
  submitButtonText: string =  'Submit';
  ngOnInit() {
    
    console.log(this.data);
    this.mobileNumber = this.data.mobileNumber;
    this.totalAvailablePoints = this.data.totalAvailablePoints;
    const url = '/api/Offer/GetPointsValue';
    this._HttpServiceService.Get(url).subscribe(response => {
      
      this.pointsValue = response.data;
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  submitRequest() {
    this.submitButtonText = 'Processing...';
    this.isDisabled = true;
    if(this.searchValueFromEvent > 0) {
    const url = '/api/Offer/SendUserPointRedeemRequest?PointsRedeem='+this.searchValueFromEvent+'&RequestedAmount='+this.convertedPoints+'&PGId=08AFFF1449F2948FCADCD28DD550C434&mobileNumber=' + this.mobileNumber + '&Points=' + this.searchValueFromEvent;
    this._HttpServiceService.Get(url).toPromise().then(response => {
      this.responseMessage = response.status;
      if (this.responseMessage) {
        this.successMessage = "Success." + response.data;
        this.openSnackBar(response.message, "Success");
        this.submitButtonText = 'Submit';
        this.isDisabled=false;
        this.dialog.closeAll();
      }
      else {
        this.successMessage = "Not processed.";
        this.openSnackBar(response.message, "Alert");
        this.submitButtonText = 'Submit';
        this.isDisabled=false;
      }
   
    });
  }
  else {
    this.isDisabled = false;
    this.submitButtonText = 'Submit';
    this.openSnackBar("Please enter points to encash.", "Alert");
  }
  }

  applyFilter(event: any) {
    this.searchValueFromEvent = event.target.value;
    if(this.searchValueFromEvent > this.totalAvailablePoints){
      this.errorMessage = "Not enough points.";
      this.isDisabled = true;
    }
    else{
      this.errorMessage = "";
      this.isDisabled = false;
    }
    this.convertedPoints = this.searchValueFromEvent * this.pointsValue;
  }
}
