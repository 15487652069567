import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {
 
  _serverError: any;
  refresh_token;
  constructor(
    private _httpClient: HttpClient,
    private _router:Router
    
  ) { }
header:any;
Apiurl:string=environment.Apiurl;
public PostWithoutToken(url, param): Observable<any> {
  return this._httpClient.post(this.Apiurl  + url, param, { })
      .pipe(
          
      );
}
public GetWithoutToken(url): Observable<any> {
  return this._httpClient.get(this.Apiurl + url, {})
      .pipe(
          
      )
}
public Post(url, param): Observable<any> {

  this.header=this.getHeaders();
  return this._httpClient.post(this.Apiurl  + url, param, { headers: this.getHeaders() })
      .pipe(
          
      );
}
public Put(url, param): Observable<any> {
  return this._httpClient.put(this.Apiurl + url, param, { headers: this.getHeaders() })
      .pipe(
        
      );
}
public Get(url): Observable<any> {
  if(!this.isLoggedIn())
  {
    this._router.navigate(['/login/']); 
    return;
  }
  return this._httpClient.get(this.Apiurl + url, { headers: this.getHeaders() })
      .pipe(
          
      )
}
public GetTango(method): Observable<any> {
 const url='https://integration-api.tangocard.com/raas/v2/catalogs'
  return this._httpClient.get(url,{ headers: this.getTangoHeaders() })
      .pipe(
          
      )
}
 setLoginAuth(data) {
  localStorage.setItem("authlogin", JSON.stringify(data));
  localStorage.setItem("isLoggedIn", "true");
  // for refresh token
  const start_token_time = new Date().getTime() / 1000; // in sec
  localStorage.setItem("start_token_time", JSON.stringify(start_token_time));
  const refresh_in = data.expires_in - 120; // in sec
  if (refresh_in > 0) {
    this.callRefreshToken(refresh_in);
  }
  return true;
  // end
}
callRefreshToken(time) {
  if (this.refresh_token) {
    clearTimeout(this.refresh_token);
  }
  this.refresh_token = setTimeout(() => {
  }, time * 1000);
}

public isLoggedIn(): boolean {      
  let status = false;     
  if (!localStorage) {
    status = false;  
    return status; 
  } 
if(localStorage){
  if (localStorage.getItem('isLoggedIn') == "true") {      
     status = true;      
  }  
  else if(!localStorage.getItem('authlogin'))  
  {
    status = false; 
  }
  else {      
     status = false;  
     }    
  }
  else{
    status=false;
  }  
  return status;      
  }    

getHeaders() {  
  if (!localStorage.getItem('authlogin')) { 
    return; 
  } 
  const authlogin = JSON.parse(localStorage.getItem('authlogin')).data.token;
  return new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + authlogin
  }
    );

}
getTangoHeaders() {  
  return new HttpHeaders({
    'Content-Type':  'application/json',  
    'Authorization': 'Basic ' + btoa('opinionesttest:AQxioDjUudLxNa?IvqBfuVKfCmLa&pXwtwLxOMeQkUfohm'),
    'Access-Control-Allow-Origin':"*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST'
  });

}

public PostSample(url,Header): Observable<any> {
   return this._httpClient.get(url,{ headers: Header })
       .pipe(
           
       )
 }

logOut()
{
  var policy = localStorage.getItem('isLoggedIn');
  localStorage.clear();
  localStorage.setItem("cookieValue", policy);
}
}
