import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { environment } from "src/environments/environment";
// import { NgxSpinnerService } from 'ngx-spinner';
export class surveyModel {
  surveyId: number;
  title: string;
  description: string;
  surveyLimit: string;
  totalClicks: string;
  status: string;
  url: string;
}
export class otherSurveyModel {
  surveyId: number;
  title: string;
  description: string;
  points: string;
  status: string;
  url: string;
}
@Component({
  selector: "app-forum-survey",
  templateUrl: "./forum-survey.component.html",
  styleUrls: ["./forum-survey.component.scss"],
})
export class ForumSurveyComponent implements OnInit {
  ProfilePercentage: number = 40;
  todayDate: Date = new Date();
  Mysurvey: Array<surveyModel> = [];
  CopyMysurvey: Array<surveyModel> = [];
  Othersurvey: Array<otherSurveyModel> = [];
  CopyOthersurvey: Array<otherSurveyModel> = [];
  constructor(private _HttpServiceService: HttpServiceService) {
    this.bindTable(1, 10);
    this.bindForumCount();
  }
  Activeindex = 0;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {}
  forumCount: any;
  bindForumCount() {
    const url = "/api/Survey/SurveyTotalCount";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.forumCount = response.data;
    });
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }

  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    var viewportHeight = document.documentElement.clientHeight;
    var documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.body.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

    var maxScrollY = documentHeight - viewportHeight;
    if (verticalOffset == maxScrollY) {
      this.bindTable(1, 5);
    } else if (verticalOffset == 0) {
      this.bindTable(-1, 5);
    }
  }

  bindTable(pageindex: number = 1, pageSize: number = 5) {
    this.Activeindex = this.Activeindex + pageindex;
    const url =
      "/api/Survey/MySurvey?pageindex=" + this.Activeindex + "&pageSize=5";
    this._HttpServiceService.Get(url).subscribe((response) => {
      if (this.Mysurvey.length == 0) {
        this.Mysurvey = response.data;
      } else {
        this.CopyMysurvey = response.data;
        let result = this.Mysurvey.concat(this.CopyMysurvey);
        this.Mysurvey = result;
      }
    });
  }
  updateColor(progress) {
    if (progress < 21) {
      return "danger-bar";
    } else if (progress > 80) {
      return "warn-bar";
    } else {
      return "ok-bar";
    }
  }
  bindOtherSource(pageindex: number = 1, pageSize: number = 5) {
    this.Activeindex = this.Activeindex + pageindex;
    const url =
      "/api/Survey/OtherSurvey?pageindex=" +
      this.Activeindex +
      "&pageSize=5&CountryId=1";
    this._HttpServiceService.Get(url).subscribe((response) => {
      if (response.data.length > 0) {
        if (this.Othersurvey.length == 0) {
          this.Othersurvey = response.data;
        } else {
          this.Othersurvey.push(response.data);
          this.CopyOthersurvey = response.data;
          let result = this.Othersurvey.concat(this.CopyOthersurvey);
          this.Othersurvey = result;
        }
      }
    });
  }
  pageStart: number = 0;
  pageEnd: number = 100;
  pageHeight: number = 30;
  pageBuffer: number = 100;

  onScroll(event, doc) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const scrollPosition = scrollTop + offsetHeight;
    const scrollTreshold = scrollHeight - this.pageHeight;
    if (scrollPosition > scrollTreshold) {
      this.pageEnd += this.pageBuffer;
    }
  }
  Navigate(surveyId) {
    const url = "/api/User/GetGUID";
    const createSurveyUrl = environment.createSurveyUrl;
    this._HttpServiceService.Get(url).subscribe((response) => {
      window.open(
        createSurveyUrl +
          "home/SurveyLogin?Guid=" +
          response.data +
          "&SurveyID=" +
          surveyId +
          "&SurveyAns=false",
        "_blank"
      );
    });
  }
  NavigateOther(surveyId) {
    const url = "/api/User/GetGUID";
    const createSurveyUrl = environment.createSurveyUrl;
    this._HttpServiceService.Get(url).subscribe((response) => {
      window.open(
        createSurveyUrl +
          "home/SurveyLogin?Guid=" +
          response.data +
          "&SurveyID=" +
          surveyId +
          "&SurveyAns=true",
        "_blank"
      );
    });
  }
  surveyChange(event) {
    this.Activeindex = 0;
    if (event.index == 0) {
      this.Mysurvey = [] as surveyModel[];
      this.bindTable(1, 5);
    } else {
      this.Othersurvey = [] as otherSurveyModel[];
      this.bindOtherSource(1, 5);
    }
  }
}
