import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {
  Activeindex: any;

  constructor(private _HttpServiceService:HttpServiceService) { }
  OfferData:any;
  ngOnInit() {
  }
  offerRedeem(offerid)
  {}
  
}
