import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { redirectURL } from '../shared/validations.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect-page.component.html',
  styleUrls: ['./redirect-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RedirectPageComponent implements OnInit {
  param: string;

  constructor(private _HttpServiceService:HttpServiceService,private _router:Router) { 

     this.param = window.location.href.split('?')[1];
     
  }
  url:any='';
  ngOnInit() {
    
    this.url=this.param;
    this._HttpServiceService.GetWithoutToken('/api/ExtraActivities/Redirect?'+this.url).subscribe(response => {
      switch (response.data.redirect.toLowerCase()) {
        case 'dashboard':
          this.url=redirectURL.Dashboard
          break;
          case 'mysurvey':
            this.url=redirectURL.Mysurvey
            break;
            case 'unsubscribe':
              this.url=redirectURL.Unsubscribe
              break;
        default:
          break;
      }
      if(this._HttpServiceService.setLoginAuth(response))
      {
        this._router.navigate([this.url]);
      }
    });
 
  }

}
