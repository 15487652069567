import { Component, EventEmitter, OnInit, Output,ViewEncapsulation } from '@angular/core';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-questions-category',
  templateUrl: './questions-category.component.html',
  styleUrls: ['./questions-category.component.scss']
})
export class QuestionsCategoryComponent implements OnInit {
  [x: string]: any;
  mySubscription: any;
  NoQuestions:boolean;
  optAnswer:boolean=false;
  @Output() callEvent = new EventEmitter<string>();

  GetQuestionCount(): void {
    this.callEvent.next('GetQuestionCount');
  }
  constructor(private _HttpServiceService:HttpServiceService,private _router:Router,private _snackBar:MatSnackBar) {
    
    this._router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         this._router.navigated = false;
         window.scrollTo(0, 90);
      }
    });
  }
  dataSource:any;
  public CategoryId:any=1;
  ngOnInit() {
    this.getQuestions();
    this.bindOtherSource();
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  showloader:boolean=false;
  bindOtherSource()
  {
    this.showloader=true;
    const url = '/api/QuestionAnswer/OptionalQuestionCategory?pageIndex=1&pageSize=100';
    this._HttpServiceService.Get(url).subscribe(response => {
    this.dataSource = response.data;
    this.showloader=false;
    });
  }
  redirectoReleventpage(event,status)
  {
    if (status==false) {
      this.openSnackBar("No more questions", "Alert");
      return false;
    }
    {
   this.NoQuestions=false;
   this.CategoryId=event; 
   this.getQuestions();
   this.optAnswer=true;
  }
  }
  QuestionData:any;
  getQuestions()
  {
    const url="/api/QuestionAnswer/GetCategoryQuestion?categoryId="+this.CategoryId
    this._HttpServiceService.Get(url).subscribe(response => {
      
      if(response.data.question==null)
      {
        this.NoQuestions=true;
      }
    if (response.data.question.type==='dropdown') {
      response.data.question.type='dropdown'
    }
    else if(response.data.question.type==='radiobutton'||response.data.question.type==='matrixradiobutton')
    {
      response.data.question.type='radio'
    }
    else if(response.data.question.type==='multiselect')
    {
      response.data.question.type='multiselect'
    }
    else if(response.data.question.type==='checkbox' || response.data.question.type==='matrixcheckBox')
    {
      response.data.question.type='checkbox'
    }
    else
    {
      response.data.question.type='text'
    }
   
  this.QuestionData=response.data.question;
  this.fields=[
    {
      type: response.data.question.type,
      name: 'key',
      label: response.data.question.profileQuestion,
      value: '',
      required: true,
      options: response.data.answer.map(e => {
        return {
          key: e.profileAnswerId,
          label: e.profileAnswer
        };
      })
    }]
      });
     
      this.fields=[ {
        type: '',
        name: 'key',
        label: '',
        value: '',
        required: true,
      }
    ]
  }
  answerId:any=0;
  SaveAnswer(x:any) { 

    this.answerId=x.key;
    const url = '/api/QuestionAnswer/SaveQuestionAnswer';
    this._HttpServiceService.Post(url, {
    profileQuestionId: this.QuestionData.profileQuestionId,
    profileAnswerId:String(x.key),
    profileAnswer:String(x.key),
    gpcid: this.QuestionData.gpcid,
    mandatory: false
  }).subscribe(response => {
    if(response.messageCode==1)
    {    
      this.getQuestions();
      this.openSnackBar(response.message, "Success");
      this.GetQuestionCount();
      x=null;
  }
  else
  {
    this.openSnackBar(response.message, "Alert");
  }
  });
   
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  backtoCategory()
  {
    this.bindOtherSource();
    this.optAnswer=false;
  }
}
