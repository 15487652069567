import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "app-page-not-found-error",
  templateUrl: "./page-not-found-error.component.html",
  styleUrls: ["./page-not-found-error.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PageNotFoundErrorComponent implements OnInit {
  url: any;
  constructor(public _router: Router, private meta: Meta) {
    this.url = "";
    if (_router.url.includes("BlogDetailsMain")) {
      this.url = "/blogs";
    } else {
      switch (_router.url) {
        case "/survey/registration.aspx":
          this.url = "/login/register";
          break;
        case "/survey/aboutus.aspx":
          this.url = "/about-us";
          break;
        case "/FAQs.aspx":
          this.url = "/faqs";
          break;
        case "/survey/BlogDetailsMain.aspx?Id=85":
          this.url = "/blogs";
          break;
        case "/survey/Contactus.aspx":
          this.url = "/contact-us";
          break;
        case "/survey/termsofservice.aspx":
          this.url = "/terms-and-conditions";
          break;
        case "/survey/Indexlogin.aspx":
          this.url = "/login";
          break;
        case "/survey/CookiesPolicy.aspx":
          this.url = "/cookies-policy";
          break;
        case "/survey/termsofservice.aspx":
          this.url = "/terms-and-conditions";
          break;
        case "/survey/Recoverpassword.aspx":
          this.url = "/login/recoverPassword";
          break;
        case "/survey/CookiesPolicy.aspx":
          this.url = "/cookies-policy";
          break;
        case "/concept.aspx":
          this.url = "";
          break;
        case "/why-join-us.aspx":
          this.url = "/why-join-us";
          break;
        case "/why-join-us.aspx":
          this.url = "/why-join-us";
          break;
        case "/survey/Google/":
          this.url = "";
          break;
        case "/survey/login.aspx":
          this.url = "/login";
          break;
        case "/request-qoute.aspx":
          this.url = "";
          break;
        default:
          this.url = "";
          break;
      }
    }
    _router.navigate([this.url]);
  }

  ngOnInit() {
    this.meta.addTag({ name: "prerender-status-code", content: "301" });
  }
}
