import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { HttpServiceService } from 'src/app/Services/common/http-service.service';
import { ImageconverterService } from 'src/app/global/imageconverter.service';
import { ExtraActivitiesComponent } from 'src/app/global/extra-activities/extra-activities.component';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TestimonialComponent implements OnInit {


  constructor(private _HttpServiceService:HttpServiceService,private _ImageconverterService:ImageconverterService
    ,private seoService:ExtraActivitiesComponent) { }

  ngOnInit() {
    this.onload();
    this.seoService.createLinkForCanonicalURL();
  }
  receivedData: any;
    onload()
    {
      const url = '/api/Testimonial/List';
      this._HttpServiceService.GetWithoutToken(url).subscribe((data)=>{
      this.receivedData=data.data;
      });
    }

    getImage(url)
    {
      this._ImageconverterService.getBase64ImageFromURL(url).subscribe(base64data => {    
        // this is the image as dataUrl
     return 'data:image/jpg;base64,' + base64data;
      });
    }
}
