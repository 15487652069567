// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAEqKBfH_1D2u2oeSD0jssQOWbPjE7K5OQ",
    authDomain: "opinionest-214810.firebaseapp.com",
    databaseURL: "https://opinionest-214810.firebaseio.com",
    projectId: "opinionest-214810",
    storageBucket: "opinionest-214810.appspot.com",
    messagingSenderId: "56943521508",
    appId: "1:56943521508:web:9b4c230515d414aad206f1",
    measurementId: "G-5RLVFK3260",
  },
  dialogflow: {
    angularBot: "a646b15f005b4ce597e610e54cdd323f",
  },
  url: "google.com",
  createSurveyUrl: "https://betasurvey.opinionest.com/",
  TangoURL: "https://integration-api.tangocard.com/raas/v2/",
  TangoPlatform: "opinionesttest",
  TangoKey: "AQxioDjUudLxNa?IvqBfuVKfCmLa&pXwtwLxOMeQkUfohm",
  Apiurl: "https://api1.opinionest.com/",
};

//https://api3.angular.opinionest.com
//https://api1.opinionest.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
