import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ContactUsComponent } from "./contact-us/contact-us.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { BlogsComponent } from "./blogs/blogs.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import {
  MatOptionModule,
  MatNativeDateModule,
  MatRippleModule,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { A11yModule } from "@angular/cdk/a11y";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ChatModule } from "../chat/chat.module";
import { ChatDialogComponent } from "../chat/chat-dialog/chat-dialog.component";
import { TestimonialComponent } from "./testimonial/testimonial.component";
import { BlogDetailsComponent } from "./blog-details/blog-details.component";
import { VideoComponent } from "../global/video/video/video.component";
import { WebLeftNavigationComponent } from "./common/web-left-navigation/web-left-navigation.component";
import { FooterWebComponent } from "./common/footer-web/footer-web.component";
import { HeaderWebComponent } from "./common/header-web/header-web.component";
import { RouterModule } from "@angular/router";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { WhyJoinUsComponent } from "./why-join-us/why-join-us.component";
import { DataProtectionComponent } from "./data-protection/data-protection.component";
import { CookiesPolicyComponent } from "./cookies-policy/cookies-policy.component";
import { FaqComponent } from "./faq/faq.component";
import { SafePipe } from "./custompipe";
import { niceDateFormatPipe } from "./customdatepipe";
import { TangoComponent } from "./tango/tango.component";
import { PageNotFoundErrorComponent } from "./page-not-found-error/page-not-found-error.component";
import { ContactUsThankYouComponent } from "./contact-us-thank-you/contact-us-thank-you.component";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { PageNotFound404Component } from "./page-not-found404/page-not-found404.component";
import { GrievancePolicyComponent } from "./grievance-policy/grievance-policy.component";
import { ChinaPrivacyComponent } from './china-privacy/china-privacy.component';
import { CaliforniaPrivacyNoticeComponent } from './california-privacy-notice/california-privacy-notice.component';
@NgModule({
  declarations: [
    ContactUsComponent,
    AboutUsComponent,
    BlogsComponent,
    TestimonialComponent,
    BlogDetailsComponent,
    VideoComponent,
    WebLeftNavigationComponent,
    FooterWebComponent,
    HeaderWebComponent,
    HowItWorksComponent,
    WhyJoinUsComponent,
    DataProtectionComponent,
    CookiesPolicyComponent,
    FaqComponent,
    SafePipe,
    niceDateFormatPipe,
    PageNotFoundErrorComponent,
    ContactUsThankYouComponent,
    PageNotFound404Component,
    GrievancePolicyComponent,
    ChinaPrivacyComponent,
    CaliforniaPrivacyNoticeComponent,
  ],
  exports: [WebLeftNavigationComponent, FooterWebComponent, HeaderWebComponent],
  imports: [
    RouterModule,
    ChatModule,
    CommonModule,
    HttpClientModule,
    BrowserModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    ShareButtonsModule,
  ],
  entryComponents: [ChatDialogComponent, VideoComponent],
})
export class WebViewModule {}
