import { Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject, iif } from "rxjs";
import * as firebase from "firebase";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { StartDashboardComponent } from "src/app/popups/start-dashboard/start-dashboard.component";
import { Breadcrumb } from "pp-breadcrumbs";
import {
  Component,
  OnInit,
  Input,
  Injectable,
  ViewEncapsulation,
} from "@angular/core";
import { ImageconverterService } from "src/app/global/imageconverter.service";
import { HtmlEncodeService } from "src/app/global/shared/html-encode.service";
import { MessagingService } from "src/app/Services/messaging.service";
import { forkJoin, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
// import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private _HttpServiceService: HttpServiceService) { }
  public getProfilePercentageAndProfileDetails(): Observable<any[]> {
    const urlProfilePercentage = "/api/User/GetProfilePercentage";
    const urlProfile = "/api/User/GetProfile";
    let response1 = this._HttpServiceService.Get(urlProfilePercentage);
    let response2 = this._HttpServiceService.Get(urlProfile);
    var data = forkJoin([response1, response2]);
    return data;
  }
}
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  [x: string]: any;

  constructor(
    private dataService: DataService,
    public _router: Router,
    public _htmlEncodeService: HtmlEncodeService,
    private _formBuilder: FormBuilder,
    private _HttpServiceService: HttpServiceService,
    public dialog: MatDialog,
    public _ImageconverterService: ImageconverterService,
    private messagingService: MessagingService
  ) {
    //this.spinner.show();
    if (!this._HttpServiceService.isLoggedIn()) {
      this._router.navigate(["../login"]);
    } else {
      this.getDashboardData();
      this.dataService
        .getProfilePercentageAndProfileDetails()
        .subscribe((responseList) => {
          this.responseData1 = responseList[0].data;
          this.responseData2 = responseList[1].data;
          this.ProfilePercentage = responseList[0].data.percentage;
          this.profileProgress = responseList[0].data.percentage;
          this.fullName = responseList[1].data.fullName;
          this.lastName = responseList[1].data.lastName;
          this.country = responseList[1].data.countryName;
          this.phone = responseList[1].data.phoneNumber;
          console.log(responseList[1].data.phoneNumber);
          // this.openMobileDialog();

          //If PhoneNumber is not empty then excute below code
          // if (this.phone == "N/A" || this.phone == "") {
          //   this.openMobileDialog();
          // }



        });
    }
  }
  color: ThemePalette = "primary";
  mode: ProgressSpinnerMode = "determinate";
  value = 50;
  user: any;
  message;
  todayDate: Date = new Date();
  totalRecords: any = 0;
  public responseData1: any;
  public responseData2: any;

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(StartDashboardComponent, {
      width: "700px",
      data: {
        switchMobile: false,
        profileUpdated: this.lastName == "N/A" || this.lastName == "",
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      this.animal = result;
    });
  }
  openMobileDialog(): void {
    const dialogRef = this.dialog.open(StartDashboardComponent, {
      width: "700px",
      data: {
        switchMobile: true,
        profileUpdated: !(this.lastName == "N/A" || this.lastName == ""),
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      this.animal = result;
    });
  }
  signOut(): void {
    let key = "LogedOut";
    localStorage.setItem(key, "LogedOut");
    this._router.navigate(["../login"]);
  }
  getPermission() {
    this.messaging
      .requestPermission()
      .then(() => {
        console.log("Notification permission granted.");
        return this.messaging.getToken();
      })
      .then((token) => {
        console.log("This ia a token", token);
        this.updateToken(token);
        this.saveTokenInDB(token);
      })
      .catch((err) => {
        console.log("Unable to get permission to notify.", err);
      });
  }
  receiveMessage(): void {
    this.messaging.onMessage((payload) => {
      console.log("Message received. ", payload);
      this.currentMessage.next(payload);
    });
  }
  totalPoints: number = 0;
  openSurvey: number = 0;
  activeSurvey: number = 0;
  referalPoint: number = 0;
  forum: number = 0;
  profileComplete: number = 0;
  ProfilePercentage: number = 0;
  blog: any;
  dailyFeeds: number = 0;
  receivedDataFeed: any;
  ActiveFeed: number = 0;
  fullName: any;
  lastName: any;
  country: any;
  phone: any;
  profileProgress: number = 0;
  referralList: any;
  totalReferralCount: number;
  Notification: any;
  mySurvey: any;
  offerCount: number;
  referralCount: number;
  spinModal: boolean;

  saveTokenInDB(token: any) { }
  forumCount: any = 0;
  bindForumCount() {
    const url = "/api/Survey/SurveyTotalCount";
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.forumCount = response.data;
    });
  }

  getDashboardData() {
    const url = "/api/DashBoard/DashBoard";
    const urlReferral = "/api/Home/ReferralList?pageindex=1&pageSize=500";
    // const urlNotification = '/api/User/Notifications';
    const urlNotificationCount = "/api/User/Notification/Count";

    const urlOfferList =
      '/api/Offer/OfferList?offerType="O"&pageindex=1&pageSize=9999';

    const urlSurvey = "/api/MySurvey?pageindex=1&pagesize=3";

    this._HttpServiceService.Get(urlSurvey).subscribe((responseSurvey) => {
      this.mySurvey = responseSurvey.data;
      //this.bindForumCount();
      this.totalRecords = responseSurvey.data.totalRecords;
    });

    // this._HttpServiceService.Get(urlNotification).subscribe(response => {
    //   this.HeaderNotification= response.data;//slice(0,1);

    // });
    this._HttpServiceService.Get(urlNotificationCount).subscribe((response) => {
      this.Notification = response.data;
    });

    this._HttpServiceService.Get(urlReferral).subscribe((response) => {
      if (response.data) {
        this.referalPoint = response.totalPoint;
        this.referralList = response.data.slice(0, 3);
        this.totalReferralCount =
          response.data.length - 3 > 0 ? response.data.length - 3 : 0;
        this.referralCount = response.data.length;
      }
    });

    this._HttpServiceService.Get(urlOfferList).subscribe((response) => {
      if (response.data) {
        this.offerCount = response.data.length;
      }
    });
    this._HttpServiceService.Get(url).subscribe((response) => {
      if (response.data) {
        this.blog = response.data.blogs;
        this.totalPoints = response.data.totalPoints;
        this.openSurvey = response.data.openSurvey;
        this.activeSurvey = response.data.activeSurvey;
        //this.referalPoint= response.data.referalPoint;
        this.forum = response.data.forum;
        this.dailyFeeds = response.data.dailyFeeds;

        if (response.data.popupStatus) {
          this.openDialog();
        }
      }
    });
  }

  transfer(card) {
    document.body.classList.remove("open_notiffication_bar");
    if (card === 1) {
      this._router.navigate(["dashboard"]);
    } else if (card === 2) {
      this._router.navigate(["dashboard/offers-and-rewards"]);
    } else if (card === 3) {
      this._router.navigate(["dashboard/forum"]);
    } else if (card === 4) {
      this._router.navigate(["dashboard/refer-and-earn"]);
    } else if (card === 6) {
      this._router.navigate(["dashboard/daily-feed"]);
    } else if (card === 7) {
      this._router.navigate(["dashboard/my-survey"]);
    } else if (card === 8) {
      this._router.navigate(["blogs"]);
    }
  }
  transferBlog(header) {
    this._router.navigate(["/blogs/", header]);
  }
  getImage(url) {
    this._ImageconverterService
      .getBase64ImageFromURL(url)
      .subscribe((base64data) => {
        // this is the image as dataUrl
        return "data:image/jpg;base64," + base64data;
      });
  }
  toHTML(content) {
    return this._htmlEncodeService.toHTML(content);
  }
  editProfile() {
    this._router.navigate(["dashboard/profile-settings"]);
  }

  toggleNotificationBar() {
    document.body.classList.toggle("open_notiffication_bar");
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }
  spinModalToggler() {
    this.spinModal = !this.spinModal;
  }
}
