import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ExtraActivitiesComponent } from 'src/app/global/extra-activities/extra-activities.component';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit {
  title = 'Opinionest: Best Online Paid Surveys Sites in India and USA';
  description='Opinionest is one of the best online paid survey sites in the USA and India. Register with Opinionest today, and let your opinion do the talking!';
  keywords='Opinionest, online paid surveys, USA and India, Register with Opinionest today,opinion do the talking! ';



  constructor(private titleService: Title,private meta: Meta,private seoService:ExtraActivitiesComponent) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name: 'keywords', content: this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'og:title', content: this.title});
    this.meta.addTag({name: 'og:site_name', content: 'Opinionest'});
    this.meta.addTag({name: 'og:url', content: 'https://opinionest.com/'});
    this.meta.addTag({name: 'og:description', content:this.description});
    this.meta.addTag({name: 'og:type', content: 'website'});
    this.meta.addTag({name: 'og:image', content: 'https://opinionest.com/assets/images/logo-color.png'});
    this.seoService.createLinkForCanonicalURL();
  }

}
