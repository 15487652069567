import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-china-privacy",
  templateUrl: "./china-privacy.component.html",
  styleUrls: ["./china-privacy.component.scss"],
})
export class ChinaPrivacyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
