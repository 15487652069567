import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-not-found404',
  templateUrl: './page-not-found404.component.html',
  styleUrls: ['./page-not-found404.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageNotFound404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
