import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ExtraActivitiesComponent } from "src/app/global/extra-activities/extra-activities.component";
import { HttpServiceService } from "src/app/Services/common/http-service.service";

interface Categories {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContactUsComponent implements OnInit {
  title = "Online Surveys For Money - Opinionest";
  description =
    "Looking for online surveys that pay cash ? You have landed at the right place! Contact us for more details.";
  keywords = "online surveys, pay cash,contact us.";

  formSubmit: boolean = false;
  contactUs: boolean = true;
  selectedItem: string;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  isValidFormSubmitted = false;
  referenceNumber: string;
  userName: string;
  emailAddress: string;
  isEnterprise: boolean;
  isExistingUser: boolean = true;
  showForm: boolean = false;
  nextPressed: boolean = false;
  contactRaio: string;
  category: Categories[] = [
    { value: "0", viewValue: "Offer & Rewards" },
    { value: "1", viewValue: "Referrals" },
    { value: "2", viewValue: "Report a bug" },
    { value: "2", viewValue: "Privacy/GDPR Request" },
    { value: "2", viewValue: "Others" },
  ];
  socialref: Categories[] = [
    { value: "SocialMedia", viewValue: "Social Media" },
    { value: "Event", viewValue: "Event" },
    { value: "Friends", viewValue: "Friends" },
    { value: "Colleagues", viewValue: "Colleagues" },
    { value: "Websites", viewValue: "Websites" },
    { value: "Others", viewValue: "Others" },
  ];
  disableButton: string = "Submit";
  visitorType: number = 1;
  constructor(
    public _HttpServiceService: HttpServiceService,
    public _snackBar: MatSnackBar,
    private titleService: Title,
    private meta: Meta,
    private seoService: ExtraActivitiesComponent,
    public _router: Router
  ) { }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  ngOnInit() {
    this.contactUs = true;
    this.formSubmit = false;
    this.titleService.setTitle(this.title);
    this.meta.addTag({ name: "keywords", content: this.keywords });
    this.meta.addTag({ name: "description", content: this.description });
    this.meta.addTag({ name: "og:title", content: this.title });
    this.meta.addTag({ name: "og:site_name", content: "Opinionest" });
    this.meta.addTag({ name: "og:url", content: "https://opinionest.com/" });
    this.meta.addTag({ name: "og:description", content: this.description });
    this.meta.addTag({ name: "og:type", content: "website" });
    this.meta.addTag({
      name: "og:image",
      content: "https://opinionest.com/assets/images/logo-color.png",
    });
    this.seoService.createLinkForCanonicalURL();
  }

  onSubmit(f: NgForm) {
    if (f.invalid) {
      return;
    }
    this.disableButton = "Please wait";
    this.isValidFormSubmitted = false;
    const url = "api/Home/ContactFormSubmission";
    let companyProfileValue = [];
    if (f.value.companyProfile) {
      companyProfileValue.push(1);
    }
    if (f.value.b2cPanelBook) {
      companyProfileValue.push(2);
    }
    if (f.value.HealthCarePanelBook) {
      companyProfileValue.push(3);
    }
    if (f.value.CatiDeck) {
      companyProfileValue.push(4);
    }
    let body = {
      visitorType: this.visitorType,
      contactName: f.value.name,
      contactEmail: f.value.email,
      categoryId: f.value.selectedItem ? f.value.selectedItem : 0,
      query: f.value.query,
      hearAboutUs: f.value.hearAboutUsValue,
      hearAboutUsQuery: f.value?.hearAboutUsQuery
        ? f.value?.hearAboutUsQuery
        : "",
      download: companyProfileValue.join(","),
      contactNo:
        f.value.contactnumber == undefined
          ? "0000000000"
          : f.value.contactnumber,
    };
    this._HttpServiceService
      .PostWithoutToken(url, body)
      .subscribe((response) => {
        this.disableButton = "Submit";
        if (response.code === 200) {
          this.openSnackBar(response.message, "Succesfull");
          this.referenceNumber = response.data;
          this.contactUs = false;
          this.formSubmit = true;
          f.resetForm();
          localStorage.setItem(
            "referenceNumber",
            this.referenceNumber.toString()
          );
          localStorage.setItem(
            "isExistingUser",
            this.isExistingUser.toString()
          );
          this._router.navigate(["contact-us/thank-you/"]);
          if (this.visitorType === 2 && companyProfileValue.length > 0) {
            this.downloadFile(response);
          }
        } else {
          this.disableButton = "Submit";
        }
        // if (response.message === "Success") {
        //   this.openSnackBar("Record saved successfully", "Succesfull");
        // }
      }, err => {
        console.log(err);
        this.disableButton = "Submit";
        this.openSnackBar(JSON.stringify(err.error), "error")
      })
  }

  downloadFile(response) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;base64," + response.data.fileContents
    );
    element.setAttribute("download", response.data.fileDownloadName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getProfile() {
    if (this._HttpServiceService.isLoggedIn()) {
      const url = "/api/User/GetProfile";

      this._HttpServiceService.Get(url).subscribe(
        (response) => {
          if (response) {
            this.userName = response.data.fullName;
            this.emailAddress = response.data.emailAddress;
            console.log(this.userName);
            console.log(this.emailAddress);
          }
        },
        (err) => { }
      );
    }
  }

  getAboutUsCategory() {
    const url = "/api/Home/HearAboutUsCategory";

    this._HttpServiceService.GetWithoutToken(url).subscribe(
      (response) => {
        if (response) {
          this.socialref = response.data;
        }
      },
      (err) => { }
    );
  }

  handleChange(eventPressed: any) {
    if (eventPressed.value == 1) {
      this.visitorType = 1;
      this.isExistingUser = true;
      this.isEnterprise = false;
    }
    if (eventPressed.value == 2) {
      this.visitorType = 2;
      this.isEnterprise = true;
      this.isExistingUser = false;
    }
  }

  onNext() {
    this.nextPressed = true;
    this.showForm = true;
    this.getProfile();
    this.getAboutUsCategory();
  }

  goBack() {
    this.nextPressed = false;
    this.showForm = false;
  }
}
