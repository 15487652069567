import { Component, OnInit, NgModule, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/Services/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { customValidations } from "src/app/global/shared/validations.enum";
import { MatDialog } from "@angular/material/dialog";
import { HttpServiceService } from "src/app/Services/common/http-service.service";
import { IpServiceService } from "src/app/Services/common/ip-service.service";
import { Observable } from "rxjs";
import { User } from "firebase";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/observable/timer";
// import { NgxSpinnerService } from 'ngx-spinner';
import { RouterModule } from "@angular/router";
import { SharedService } from "src/app/Services/common/SharedService";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Component({
  selector: "app-view-edit-profile",
  templateUrl: "./view-edit-profile.component.html",
  styleUrls: ["./view-edit-profile.component.scss"],
})
export class ViewEditProfileComponent implements OnInit {
  ViewEditProfille: FormGroup;
  user: Observable<User>;
  private subscription: Subscription;
  private timer: Observable<any>;
  HeaderNotification: any;
  Notification: any;
  formdetails: any;
  PointsDetails: any;
  profileProgress: number = 0;
  offerStatusToUnsubscribe: any = false;
  selfDeclaration: any = true;
  optionalCountData: any;
  MendatoryCountData: any;
  individualCheck: boolean;
  survey: boolean;
  cookies: boolean;
  initials: string;
  needOptional: boolean;
  constructor(
    public _router: Router,
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    public dialog: MatDialog,
    private ip: IpServiceService,
    private _snackBar: MatSnackBar,
    private _HttpServiceService: HttpServiceService,
    private metaService: SharedService,
    private http: HttpClient
  ) {
    //this.spinner.show();
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.createViewEditProfille();
    this.GetQuestionCount();
    this.GetEmailSubscribeDetails();
  }
  Termes: any;
  ProfilePercentage: number = 0;
  getProfileDetails() {
    const url = "/api/User/GetProfile";
    this._HttpServiceService.Get(url).subscribe(
      (response) => {
        if (response) {
          this.ViewEditProfille.patchValue(response.data);
          this.formdetails = response.data;
        }
      },
      (err) => {}
    );
  }
  getUserDetails() {
    const url = "/api/User/Notifications";
    const urlCount = "/api/User/Notification/Count";
    this._HttpServiceService.Get(urlCount).subscribe((response) => {
      this.HeaderNotification = response.data;
      this.initials = this.HeaderNotification.fullName
        .split(" ")
        .map((n) => n[0])
        .join("");
      //this.spinner.hide();
    });
    this._HttpServiceService.Get(url).subscribe((response) => {
      this.Notification = response.data;
    });
    const urlDashboard = "/api/DashBoard/DashBoard";
    this._HttpServiceService.Get(urlDashboard).subscribe((response) => {
      if (response.data) {
        this.PointsDetails = response.data;
        //this.spinner.hide();;
      }

      const urlProfilePercentage = "/api/User/GetProfilePercentage";
      this._HttpServiceService
        .Get(urlProfilePercentage)
        .subscribe((response) => {
          //this.spinner.hide();
          this.profileProgress = response.data.percentage;
        });
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  Update() {
    const url = "/api/User/editprofile";
    this._HttpServiceService
      .Post(url, this.ViewEditProfille.getRawValue())
      .subscribe((response) => {
        if (response.messageCode == 1) {
          this.openSnackBar(response.message, "Success");
        } else {
          this.openSnackBar(response.message, "Alert");
        }
      });
  }
  signOut(): void {
    var policy = localStorage.getItem("isLoggedIn");
    localStorage.clear();
    localStorage.setItem("cookieValue", policy == "true" ? "1" : "0");
    this._router.navigate(["/login"]);
  }
  Delete() {
    const url = "/api/Registation/DeleteAccount";
    this._HttpServiceService.Post(url, "").subscribe((response) => {
      if (response.messageCode == 1) {
        this.openSnackBar(response.message, "Success");
        this.signOut();
      } else {
        this.openSnackBar(response.message, "Alert");
      }
    });
  }
  Subscribe() {
    const url = "/api/Registation/EmailSubscrible";
    let body = {
      UserEmailSubscrible: this.survey,
      OfferEmailSubscription: this.individualCheck,
      Cookies: this.cookies,
    };
    this._HttpServiceService.Post(url, body).subscribe((response) => {
      if (response.messageCode == 1) {
        this.openSnackBar(response.message, "Success");
      } else {
        this.openSnackBar(response.message, "Alert");
      }
    });
  }
  GetQuestionCount() {
    this.getProfileDetails();
    this.getUserDetails();
    const optionalCount = "/api/QuestionAnswer/OptionalQuestionTotal";
    const mendatoryCount = "/api/QuestionAnswer/MandatoryQuestionTotal";
    this._HttpServiceService.Get(optionalCount).subscribe((response) => {
      this.optionalCountData = response.data;
    });
    this._HttpServiceService.Get(mendatoryCount).subscribe((response) => {
      this.MendatoryCountData = response.data;
    });
  }

  createViewEditProfille() {
    this.ViewEditProfille = this._formBuilder.group({
      userId: [0],
      fullName: ["", Validators.required],
      firstName: [[]],
      lastName: [""],
      phoneNumber: [""],
      profileImage: [""],
      countryId: [0],
      countryName: ["", { disabled: true }],
      gender: [0],
      dateOfBirth: Date,
      emailAddress: [""],
      pincode: ["", { disabled: true }],
    });
  }
  GetEmailSubscribeDetails() {
    const emailSubscription = "/api/Registation/GetEmailSubscription";
    this._HttpServiceService.Get(emailSubscription).subscribe((response) => {
      this.individualCheck = response.message.offerEmailSubscription;
      this.survey = response.message.emailSubscrible;
      this.cookies = response.message.cookies;
      console.log(this.individualCheck);
      console.log(this.survey);
      console.log(this.cookies);
    });
  }

  setIndividual(event: any) {
    this.individualCheck = event.checked;
  }
  setSurveyEmail(event: any) {
    this.survey = event.checked;
  }
  setCookies(event: any) {
    this.cookies = event.checked;
  }
  setSelfDeclaration(event: any) {
    if (event.checked) {
      this.selfDeclaration = false;
    } else {
      this.selfDeclaration = true;
    }
  }

  indicate() {
    this.needOptional = true;
    this.timer = Observable.timer(6000);
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.needOptional = false;
    });
  }

  toggleMenuOpen() {
    document.body.classList.toggle("menu_open");
  }

  private clientId = '86xlqlj9i8120n'; // Replace with your actual Client ID
  private clientSecret = 'WPL_AP1.W9aPILuVUWZCbgC0.54a3eQ=='; // Replace with your actual Client Secret
  private redirectUri = 'https://opinionest.com/dashboard/linkedin-settings';
  private stateid = '3Q657QSqmKJxIwhx'; // Replace with a unique state for security
  private scope = 'email'; // Scope for profile and email permissions


  loginWithLinkedIn() {
    // Generate a unique state value (e.g., using a random string or UUID)
    const state = this.generateRandomState();
    const scope = 'email';

    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${state}&scope=${encodeURIComponent(scope)}`;
    console.log("authUrl", authUrl)
    window.open(authUrl, '_blank'); 
  }


  private generateRandomState(): string {
    return Math.random().toString(36).substring(2, 15);
  }



  exchangeCodeForToken(code: string) {
    const tokenUrl = 'https://www.linkedin.com/oauth/v2/accessToken';

    // Set up the data required by LinkedIn's API for token exchange
    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', this.redirectUri);
    body.set('client_id', this.clientId);
    body.set('client_secret', this.clientSecret);

    this.http.post(tokenUrl, body.toString(), {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    }).subscribe(
      (response: any) => {
        const accessToken = response.access_token;
      },
      error => {
        console.error('Error exchanging code for token', error);
      }
    );
  }

}
